import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";
import { format } from "date-fns";
import _ from "lodash";

interface Props {
  creditAppModalData: any;
  isCreditAppNeedsRecertification?: boolean;
}

export const ApplicationDateLayout = ({ creditAppModalData, isCreditAppNeedsRecertification }: Props) => {
  const classes = useStyles();
  const recertificationDate = _.get(creditAppModalData, "recertificationDate");

  if (!creditAppModalData) {
    return null;
  }

  return (
    <>
      <Typography>
        Date of Application: {creditAppModalData.createdDateTime}
        {isCreditAppNeedsRecertification && !recertificationDate && (
          <ErrorOutlineIcon className={classes.recerteficationErrorIcon} />
        )}
      </Typography>
      {recertificationDate && (
        <Typography>
          Recertification Date: {format(+recertificationDate, "MM-dd-yyyy")}
          {isCreditAppNeedsRecertification && <ErrorOutlineIcon className={classes.recerteficationErrorIcon} />}
        </Typography>
      )}
    </>
  );
};

const useStyles = makeStyles(theme => ({
  recerteficationErrorIcon: {
    color: "red",
    verticalAlign: "sub",
    marginLeft: 5,
  },
}));
