import React, { ReactNode, useState } from "react";
import ReactTable from "react-table";
import { convertToDate, formatCurrency } from "../../../utils";
import { connect } from "react-redux";
import { setRefetch, setValue } from "../../../redux/actions";
import { useHistory } from "react-router-dom";
import _ from "lodash";
import { DefaultTableCell } from "../../../components/Table/DefaultTableCell";
import { Box, IconButton } from "@material-ui/core";
import { useQuery } from "@apollo/react-hooks";
import { CardContainer } from "../../../components/shared/CardContainer/CardContainer";
import { ContentViewType } from "../../../global";
import { useViewTypeContext } from "../../../contexts/contentViewType";
import { FiltersPanel } from "../../../components/shared/FiltersPanel";
import { EntityMobileCards } from "../../../components/shared/EntityMobileCards";
import { Proposal } from "@trnsact/trnsact-shared-types";
import { MoreHorizOutlined } from "@material-ui/icons";
import { ProposalMobileEntity } from "./types";
import { mobileEntityAdapter } from "./lib/mobileEntityAdapter";
import { GET_VO_PROPOSAL_LIST } from "./queries";
import { filterProposalListData } from "./lib/filterProposalListData";

function VOProposalList(props: any) {
  const voId = props.vo.vendorOpportunityId;

  const statusesSel: any = [];

  const history = useHistory();

  const [tableFilter, setTableFilter] = useState<{ search: string; status: string[] }>({ search: "", status: ["all"] });

  const [pageNumber, setPageNumber] = useState<number>(0);
  const [pageSizeValue, setPageSizeValue] = useState<number>(5);
  const [savedListSettings, setSavedListSettings] = useState<{ page: number; pageSize: number }>({
    page: 0,
    pageSize: 10,
  });

  function handleProposalRowClick(propId: any) {
    history.push(`/proposalDetail/${propId}`);
  }

  function processProposalListData(proposals: any) {
    if (proposals && _.isArray(proposals.proposals)) {
      const statuses: any[] = [];

      proposals.proposals.forEach((item: any) => {
        try {
          item.contactName = item.firstName ? item.firstName + item.lastName : item.fullName;
          item.amountRequested = formatCurrency(item.amountRequested);
          item.createdDateTime = convertToDate(item.createdDateTime);

          if (statuses.find(listItem => listItem === item.status) === undefined && !!item.status) {
            statuses.push(item.status);
          }

          if (item.paymentId && item.lenderPaymentOptions && item.lenderPaymentOptions.length > 0) {
            const acceptedPayment = item.lenderPaymentOptions.find((lpo: any) => lpo.paymentId === item.paymentId);

            item.acceptedPayment = `Payment Amount: ${formatCurrency(acceptedPayment.payment)}
            Payment Term: ${acceptedPayment.term}`;
          } else {
            item.acceptedPayment = "N/A";
          }
        } catch (error) {
          console.log(error);
        }
      });

      statuses.forEach(item => {
        const statusesSelItem = {
          value: item,
          text: item ? item : "None",
        };
        statusesSel.push(statusesSelItem);
      });

      return [...proposals.proposals];
    }

    return [];
  }

  const { loading, error, data } = useQuery(GET_VO_PROPOSAL_LIST, {
    variables: {
      voId,
    },
  });

  if (loading) return "Loading...";

  if (error) return <h3>{String(error)}</h3>;

  const filteredProposalListData = processProposalListData(filterProposalListData(data, tableFilter));

  const { contentViewType } = useViewTypeContext();

  const renderByViewType: Record<ContentViewType, ReactNode> = {
    [ContentViewType.Mobile]: (
      <EntityMobileCards<Proposal, ProposalMobileEntity>
        entities={filteredProposalListData}
        entityAdapter={mobileEntityAdapter}
        actionsPanel={({ id }) => (
          <>
            <IconButton size="small" onClick={() => handleProposalRowClick(id)}>
              <MoreHorizOutlined color="primary" />
            </IconButton>
          </>
        )}
      />
    ),
    [ContentViewType.Desktop]: (
      <ReactTable
        noDataText="No rows found"
        data={filteredProposalListData}
        getTrProps={(state: any, rowInfo: any) => ({
          style: { cursor: "pointer" },
          onClick: () => handleProposalRowClick(rowInfo.original.id),
        })}
        columns={[
          {
            Header: "Created On",
            accessor: "createdDateTime",
            resizable: false,
            Cell: ({ value }: any) => <DefaultTableCell value={value} />,
          },
          {
            Header: "Company",
            accessor: "businessName",
            Cell: ({ value }: any) => <DefaultTableCell value={value} />,
          },
          {
            Header: "Contact",
            accessor: "contactName",
            Cell: ({ value }: any) => <DefaultTableCell value={value} />,
          },
          {
            Header: "Amount",
            accessor: "amountRequested",
            value: "test",
            Cell: ({ value }: any) => <DefaultTableCell value={value} />,
          },
          {
            Header: "Status",
            accessor: "status",
            Cell: ({ value }: any) => <DefaultTableCell value={value} />,
          },
          {
            Header: "Accepted Payment",
            accessor: "acceptedPayment",
            Cell: ({ value }: any) => <DefaultTableCell value={value} />,
          },
          {
            Header: "Owner",
            accessor: "salesPersonName",
            Cell: ({ value }: any) => <DefaultTableCell value={value} />,
          },
        ].map(header => ({ ...header, headerStyle: { fontWeight: "500", padding: "0.5rem" } }))}
        defaultPageSize={savedListSettings.pageSize}
        pageSize={pageSizeValue}
        onPageChange={setPageNumber}
        onPageSizeChange={changePageSize => {
          setPageSizeValue(changePageSize);
          setPageNumber(0);
        }}
        page={pageNumber}
        showPaginationBottom
        showPaginationTop={false}
        className="-striped -highlight"
      />
    ),
  };

  return (
    <CardContainer title="Proposals">
      <Box style={{ display: "flex", flexDirection: "column", gap: "12px" }}>
        <FiltersPanel
          options={{ status: { label: "Status", values: statusesSel } }}
          onSearchChange={(filter, nextValue) => {
            setTableFilter(prev => ({ ...prev, [filter]: nextValue }));
          }}
          onOptionsChange={(filter, nextValue) => {
            setTableFilter(prev => ({ ...prev, [filter]: nextValue }));
          }}
        />

        {renderByViewType[contentViewType]}
      </Box>
    </CardContainer>
  );
}

const mapStateToProps = (state: any) => ({
  account: state.account,
  userProfile: state.userProfile,
  vp: state.vp,
  refetch: state.refetch,
  creditSubmissions: state.creditSubmissions,
});

const mapDispatchToProps = {
  reduxSetValue: setValue,
  setRefetch,
};

export default connect(mapStateToProps, mapDispatchToProps)(VOProposalList);
