import React, { SyntheticEvent } from "react";
import { Typography } from "@material-ui/core";
import { TableCell } from "./TableCell";

interface Props {
  value: string;
  formatter?: (value: string) => string;
  onCellClick?: (event: SyntheticEvent) => void;
}

export const DefaultTableCell = ({ value, onCellClick = () => {}, formatter }: Props) => {
  return (
    <TableCell onCellClick={onCellClick}>
      <Typography variant="body2">{formatter ? formatter(value) : value}</Typography>
    </TableCell>
  );
};
