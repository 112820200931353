import { Proposal } from "@trnsact/trnsact-shared-types";
import { ProposalMobileEntity } from "../types";

export const mobileEntityAdapter = (entity: Proposal): ProposalMobileEntity => ({
  id: entity.id!,
  dateAndTime: entity.createdDateTime!,
  original: entity,
  dataForView: [
    {
      label: "Company",
      type: "default",
      value: entity.businessName,
    },
    {
      label: "Contact",
      type: "default",
      value: (entity as any).contactName,
    },
    {
      label: "Amount",
      type: "default",
      value: entity.amountRequested,
    },
    {
      label: "Status",
      type: "default",
      value: entity.status,
    },
    {
      label: "Accepted Payment",
      type: "default",
      value: (entity as any).acceptedPayment,
    },
    {
      label: "Owner",
      type: "default",
      value: entity.salesPersonName,
    },
  ],
});
