import React from "react";
import { TextField, TextFieldProps } from "@material-ui/core";

interface FormInputProps extends Omit<TextFieldProps, "onChange"> {
  onChange?: (value: string) => void;
  formatter?: (value: string) => string;
}

export const FormInput: React.FC<FormInputProps> = ({ onChange, formatter, ...other }) => {
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    let value: string = event.target.value;
    if (onChange) {
      if (formatter) {
        value = formatter(value);
      }
      onChange(value);
    }
  };

  return <TextField size="small" variant="outlined" fullWidth onChange={handleChange} {...other} />;
};
