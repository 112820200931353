import React from "react";
import { LoadModal } from "./LoadModal";

export const CommonModals = () => {
  return (
    <>
      <LoadModal />
    </>
  );
};
