export const filterProposalListData = (proposalListData: any, tableFilter: any) => {
  const isFiltersEmpty = !Object.values(tableFilter).filter(Boolean).length;

  if (isFiltersEmpty) return proposalListData;

  const lowerCaseSearch = tableFilter.search.toLowerCase();

  const filterAction = {
    status: (el: any) => tableFilter.status.includes("all") || tableFilter.status.includes(el?.status),
    search: (el: any) => {
      if (
        typeof el.createdDateTime === "string" ||
        typeof el.businessName === "string" ||
        typeof el.contactName === "string" ||
        typeof String(el.amountRequested) === "string" ||
        typeof el.salesPersonName === "string"
      ) {
        return (
          (el.createdDateTime || "").toLowerCase().includes(lowerCaseSearch) ||
          (el.businessName || "").toLowerCase().includes(lowerCaseSearch) ||
          (el.contactName || "").toLowerCase().includes(lowerCaseSearch) ||
          (String(el.amountRequested) || "").toLowerCase().includes(lowerCaseSearch) ||
          (el.salesPersonName || "").toLowerCase().includes(lowerCaseSearch)
        );
      }

      return false;
    },
  };

  return {
    ...proposalListData,
    proposals: (proposalListData.proposals ?? []).filter((row: any) =>
      Object.values(filterAction).every(fn => fn(row))
    ),
  };
};
