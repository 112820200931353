import { combineReducers } from "redux";
import { creditAppReducer } from "./creditAppReducer";
import { voReducer } from "./voReducer";
import { vpReducer } from "./vpReducer";
import { accountReducer } from "./accountReducer";
import { accountRepReducer } from "./accountRepReducer";
import { userProfileReducer } from "./userProfileReducer";
import { loadingReducer } from "./loadingReducer";
import { refetchReducer } from "./refetchReducer";
import { relatedUsersReducer } from "./relatedUsersReducer";
import { chatReducer } from "./chatReducer";
import { lpReducer } from "./lpReducer";
import { setupWizardReducer } from "./setupWizardReducer";
import { setupFastTrackModalReducer } from "./setupFastTrackModalReducer";
import { creditSubmissionReducer } from "./creditSubmissionReducer";
import { chatV2Rducer } from "./chatv2Reducer";
import { modalsReducer } from "./modalsReducer";
import { portalConfigurationReducer } from "./portalConfigurationReducer";

// Migrated from LP
import { creditSubmission } from "./creditSubmission";
import { documents } from "./documents";
import { lenderProfile } from "./lenderProfile";

export const rootReducer = combineReducers({
  account: accountReducer,
  accountRep: accountRepReducer,
  userProfile: userProfileReducer,
  vo: voReducer,
  vp: vpReducer,
  lp: lpReducer,
  creditApp: creditAppReducer,
  relatedUsers: relatedUsersReducer,
  refetch: refetchReducer,
  loading: loadingReducer,
  chat: chatReducer,
  setupWizard: setupWizardReducer,
  setupFastTrack: setupFastTrackModalReducer,
  creditSubmissions: creditSubmissionReducer,
  chatV2: chatV2Rducer,
  portalConfiguration: portalConfigurationReducer,

  // Migrated from LP
  cs: creditSubmission,
  docs: documents,
  lenderProfile: lenderProfile,

  modals: modalsReducer,
});
