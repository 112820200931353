import React, { ReactNode } from "react";
import DoneIcon from "@material-ui/icons/Done";
import { Button, Typography, Box } from "@material-ui/core";
import PriorityHighIcon from "@material-ui/icons/PriorityHigh";
import HourglassEmptyIcon from "@material-ui/icons/HourglassEmpty";
import { CreditSubmission } from "@trnsact/trnsact-shared-types/src/generated";
import { CreditSubmissionEntityMobile } from "../types";
import { CREDIT_SUBMISSION_SOURCE, CREDIT_SUBMISSION_SOURCE_HUMAN_READABLE, translateStageCodes } from "../../../utils";
import _ from "lodash";
import { lenderTypesSettings } from "../../../pages/Prequal/constants";
import rocketFastTrack from "../../../assets/img/big-rocket.svg";

const currencyFormatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
});

const stageViewByType: Record<string, ReactNode> = {
  error: <PriorityHighIcon color="error" />,
  failure: <PriorityHighIcon color="error" />,
  success: <DoneIcon />,
  "in progress": <HourglassEmptyIcon />,
};

const lenderTypesSettingsByValue = _.keyBy(lenderTypesSettings, "code");

export const mobileEntityAdapter = (
  entity: CreditSubmission,
  handleUpdateCredentials: any
): CreditSubmissionEntityMobile => ({
  id: entity.creditSubId!,
  title: entity.lenderName!,
  dateAndTime: null,
  original: entity,
  dataForView: [
    {
      label: "Submission date",
      value: entity.submissionDate,
      type: "default",
    },
    {
      label: "Stage",
      type: "component",
      value: entity.creditSubStage,
      component: ({ value }) => {
        if (entity.apiExpiredCredentials) {
          return (
            <div>
              <PriorityHighIcon /> {"Credentials Expired"}
              <Button
                size="small"
                onClick={() => {
                  handleUpdateCredentials(entity);
                }}
              >
                Update Credentials
              </Button>
            </div>
          );
        }

        return (
          <Typography content="span" variant="body2" color="textPrimary">
            {translateStageCodes(value)} {entity?.apiResult && stageViewByType[entity.apiResult]}
          </Typography>
        );
      },
    },
    {
      label: "Decision",
      value: ["failure", "error"].includes(entity?.apiResult ?? "")
        ? `Error Details: ${entity.apiDetail}`
        : entity.decision,
      type: "default",
    },
    {
      type: "component",
      label: "Screenshots",
      value: (entity as any)?.apiScreenshots,
      component: ({ value }) => (
        <>
          {!!value?.length ? (
            <Box>
              {value.map((url: any, i: number) => (
                <a key={url} target="_blank" href={url} rel="noreferrer">
                  image-{i + 1}
                </a>
              ))}
            </Box>
          ) : (
            <Typography content="span" variant="body2" color="textPrimary">
              —
            </Typography>
          )}
        </>
      ),
    },
    {
      type: "default",
      label: "Requested",
      value: entity?.amountRequested ? currencyFormatter.format(entity.amountRequested) : null,
    },
    {
      type: "default",
      label: "Approved",
      value: entity?.approvedAmount ? currencyFormatter.format(entity.approvedAmount) : null,
    },
    {
      label: "Type",
      type: "component",
      value: entity.lenderTypeCode,
      component: ({ value }) => {
        const lenderTypeSetting = lenderTypesSettingsByValue[value];

        return (
          <>
            {lenderTypeSetting && (
              <div>
                <b style={{ color: lenderTypeSetting.color }}>{lenderTypeSetting.letter}</b>
                <Typography content="span" variant="body2" color="textPrimary">
                  {lenderTypeSetting.title}
                </Typography>
              </div>
            )}
          </>
        );
      },
    },
    {
      type: "component",
      label: "Submitted by",
      value: entity.source,
      component: ({ value }) => {
        const submittedByText = (CREDIT_SUBMISSION_SOURCE_HUMAN_READABLE as any)[value];

        if ([CREDIT_SUBMISSION_SOURCE.FAST_TRACK_AUTO, CREDIT_SUBMISSION_SOURCE.FAST_TRACK_MANUAL].includes(value)) {
          return (
            <Typography content="span" variant="body2" color="textPrimary">
              <img src={rocketFastTrack} width="30px" height="30px" alt="Fast track icon" />
              {submittedByText}
            </Typography>
          );
        } else if (value === CREDIT_SUBMISSION_SOURCE.PORTAL_BY_DEALER) {
          return (
            <Typography content="span" variant="body2" color="textPrimary">
              {entity.submittedBy}
            </Typography>
          );
        } else {
          return (
            <Typography content="span" variant="body2" color="textPrimary">
              TRNSACT
            </Typography>
          );
        }
      },
    },
  ],
});
