import React, { ReactNode, useEffect, useRef, useState } from "react";
import { useLazyQuery } from "@apollo/react-hooks";
import _ from "lodash";
import { makeStyles } from "@material-ui/core/styles";
import { connect, useDispatch } from "react-redux";
import TableSearch from "components/Table/TableSearch";
import rocketFastTrack from "assets/img/big-rocket.svg";
import { Button, Modal, IconButton } from "@material-ui/core";
import { GET_CREDIT_SUBMISSIONS } from "../queries";
import LenderSubmission from "../../../pages/VendorOpportunity/LenderSubmission";
import EngsMappingModal from "../../../pages/VendorOpportunity/LenderSubmissionMappingModals/EngsMappingModal";
import { SETUP_FAST_TRACK_MODAL } from "../../../redux/types";
import LenderSubmissionDetailModal from "../../../pages/VendorOpportunity/LenderSubmissionDetailModal";
import UpdateLenderCredentials from "../../../pages/LenderAdmin/credentials";
import { setRefetch, setValue } from "../../../redux/actions";
import { SyndicationsTable } from "./table/SyndicationsTable";
import { CardContainer } from "../../shared/CardContainer/CardContainer";
import { useViewTypeContext } from "../../../contexts/contentViewType";
import { ContentViewType } from "../../../global";
import { EntityMobileCards } from "../../shared/EntityMobileCards";
import { CreditSubmission } from "@trnsact/trnsact-shared-types/src/generated";
import { CreditSubmissionEntityMobile } from "../types";
import { mobileEntityAdapter } from "../lib/mobileEntityAdapter";
import VisibilityIcon from "@material-ui/icons/Visibility";

const LenderViewTable = ({
  vp,
  vo,
  account,
  userProfile,
  lp,
  refetch,
  setRefetch,
  creditApp,
  equipmentData,
  setValue,
  setOpenEquipmentInputModal,
  setInvoiceEquipmentTabPage,
  portalConfiguration,
  handleSubmitToLenderByProfileId,
  callbackSubmissionFinished,
}: any) => {
  const classes = useStyles();

  const [creditSubmissions, setCreditSubmissions] = useState<any>();
  const [openLenderSubmissionDetailModal, setOpenLenderSubmissionDetailModal] = useState<any>(false);
  const [loading, setLoading] = useState<any>(false);
  const [creditSubId, setCreditSubId] = useState<any>(null);
  const [creditSubLenderSubmissionDetailModal, setCreditSubLenderSubmissionDetailModal] = useState<any>(null);
  const [openUpdateLenderCredentialsModal, setOpenUpdateLenderCredentialsModal] = useState<any>(false);
  const [selectedLenderProfile, setSelectedLenderProfile] = useState<any>(null);
  const [filteredCreditSubmissions, setFilteredCreditSubmissions] = useState<any>([]);
  const [pageNumber, setPageNumber] = useState<any>(0);
  const searchRef = useRef({ prevSearch: undefined, currentSearch: "" });
  const [savedListSettings, setSavedListSettings] = useState<any>({ page: 0, pageSize: 20 });
  const [enableFastTrack, setEnableFastTrack] = useState<any>(false);
  const [refetching, setRefetching] = useState<any>(false);

  const [openEngsMappingModal, setOpenEngsMappingModal] = useState<any>(false);

  const hasEngsLenderProfile = false; // lp.find(lender => lender.lenderName.toLowerCase().includes("engs"));

  const [
    getCreditSubmissions,
    {
      data: creditSubmissionsData,
      loading: creditSubmissionsLoading,
      error: creditSubmissionsError,
      startPolling: startCreditSubPolling,
      stopPolling: stopCreditSubPolling,
      refetch: creditSubmissionsRefetch,
    },
  ] = useLazyQuery(GET_CREDIT_SUBMISSIONS);

  if (creditSubmissionsLoading) console.log("Loading Credit Submissions....");
  if (creditSubmissionsError) console.log(`Error! ${creditSubmissionsError.message}`);

  useEffect(() => {
    if (_.get(creditSubmissionsData, "creditSubmissionsByDynamicsVOId")) {
      const filteredCS = creditSubmissionsData.creditSubmissionsByDynamicsVOId.filter((el: any) => el != null);
      setCreditSubmissions(creditSubmissionsData.creditSubmissionsByDynamicsVOId.filter((el: any) => el != null));

      let myData = Object.keys(filteredCS).map(key => filteredCS[key]);
      setValue("creditSubmissions", myData);
    } else {
      if (!!vo && !!vo.dynamicsVendorOpportunityId) {
        getCreditSubmissions({
          variables: {
            id: vo.dynamicsVendorOpportunityId,
            display: vp.creditSubmissionDisplay,
          },
        });
      }
    }
  }, [creditSubmissionsData, getCreditSubmissions]);

  const filterCreditSubmissions = (creditSubmissions: any) => {
    const { currentSearch } = searchRef.current;

    if (currentSearch.length === 0) {
      return creditSubmissions;
    }

    const lowerCaseSearch = currentSearch.toLowerCase().trim();
    let searchData = creditSubmissions.filter((el: any) => {
      if (
        typeof el.submissionDate === "string" ||
        typeof el.lenderName === "string" ||
        typeof el.creditSubStage === "number" ||
        typeof el.decision === "string" ||
        typeof el.amountRequested === "number" ||
        typeof el.approvedAmount === "number"
      ) {
        return (
          (el.submissionDate || "").toLowerCase().includes(lowerCaseSearch) ||
          (el.lenderName || "").toLowerCase().includes(lowerCaseSearch) ||
          (String(el.creditSubStage) || "").includes(lowerCaseSearch) ||
          (el.decision || "").toLowerCase().includes(lowerCaseSearch) ||
          (String(el.amountRequested) || "").includes(lowerCaseSearch) ||
          (String(el.approvedAmount) || "").includes(lowerCaseSearch)
        );
      }

      return false;
    });

    return [...searchData];
  };

  useEffect(() => {
    if (creditSubmissions) {
      let updatedCreditSubmissions = [...creditSubmissions];
      updatedCreditSubmissions = [...updatedCreditSubmissions].map(el => {
        const splitAPIDetail = el.apiDetail?.toString().split("Screenshots:");
        let screenshots =
          splitAPIDetail && splitAPIDetail[1]
            ? splitAPIDetail[1]
                .split("\n")
                ?.map((i: any) => i.trim())
                ?.filter((i: any) => i)
            : [];

        return {
          ...el,
          apiDetail: splitAPIDetail ? splitAPIDetail[0] : el.apiDetail,
          apiScreenshots: screenshots,
        };
      });
      setFilteredCreditSubmissions(filterCreditSubmissions(updatedCreditSubmissions));
    }
  }, [creditSubmissions, searchRef.current.currentSearch]);

  if (refetch.creditSubmissions) {
    setRefetch("creditSubmissions", false);
    creditSubmissionsRefetch();
  }

  const [openMessageModal, setOpenMessageModal] = useState<any>(false);
  const [submissionMessage, setSubmissionMessage] = useState<any>({});

  const handleOpenModal = () => setOpenMessageModal(true);

  const handleSubmissionMessage = async (message: any) => {
    await creditSubmissionsRefetch();
    return setSubmissionMessage(message);
  };

  const handleOpenLenderDetailModalView = (row: any) => {
    setCreditSubId(row.creditSubId);
    setCreditSubLenderSubmissionDetailModal(row);
    setOpenLenderSubmissionDetailModal(true);
  };

  const handleCloseLenderDetailModalView = () => {
    setOpenLenderSubmissionDetailModal(false);
    setCreditSubLenderSubmissionDetailModal(false);
    setCreditSubId("");
  };

  if (!!refetch && !!refetch.pastSubmissions) {
    creditSubmissionsRefetch();
    setRefetch("pastSubmissions", false);
  }
  const handleCloseUpdateLenderCredentialsModal = () => {
    setOpenUpdateLenderCredentialsModal(false);
  };
  const handleOpenUpdateLenderCredentialsModal = () => {
    setOpenUpdateLenderCredentialsModal(true);
  };
  function handleUpdateCredentials(rowInfo: any) {
    setSelectedLenderProfile(rowInfo);
    handleOpenUpdateLenderCredentialsModal();
  }

  const dispatch = useDispatch();

  const handleOpenFastTrackModal = (e: any) => {
    e.preventDefault();

    dispatch({
      type: SETUP_FAST_TRACK_MODAL,
      payload: {
        opened: true,
        currentVo: vo,
        vp,
        creditAppData: {
          creditApplication: creditApp,
        },
      },
    });
  };

  const handleOpenEngsMappingModal = () => {
    setOpenEngsMappingModal(true);
  };

  const handleEngsMappingModalClose = () => {
    setOpenEngsMappingModal(false);
  };

  useEffect(() => {
    if (vo) {
      const fastTrackStatus = _.get(vo, "fastTrackResults[0].applicationStatus", "");
      const whiteListToShowFastTrack = [
        "AWAITING_DEALER_APPROVAL",
        "PROPOSAL_SENT",
        "PROPOSAL_VIEWED",
        "PROPOSAL_ACCEPTED",
        "FUNDED_FAST",
      ];
      const isFastTrackEnabled =
        vp.fastTrackSetting === "YES_SET_CAMUNDA_PROCESS_CODE" ||
        vp.fastTrackSetting === "YES_AUTOMATED_FOR_DEALER" ||
        vp.fastTrackSetting === "YES_BUT_DEALER_MUST_TRIGGER";
      if (isFastTrackEnabled && fastTrackStatus && whiteListToShowFastTrack.includes(fastTrackStatus)) {
        setEnableFastTrack(true);
      }
    }
  }, [vo]);

  const { contentViewType } = useViewTypeContext();

  const renderByViewType: Record<ContentViewType, ReactNode> = {
    [ContentViewType.Mobile]: (
      <EntityMobileCards<CreditSubmission, CreditSubmissionEntityMobile>
        actionsPanel={({ original }) => (
          <>
            <IconButton size="small" color="primary" onClick={() => handleOpenLenderDetailModalView(original)}>
              <VisibilityIcon color="primary" />
            </IconButton>
          </>
        )}
        entityAdapter={entity => mobileEntityAdapter(entity, handleUpdateCredentials)}
        entities={filteredCreditSubmissions}
      />
    ),
    [ContentViewType.Desktop]: (
      <SyndicationsTable
        pageNumber={pageNumber}
        setPageNumber={setPageNumber}
        savedListSettings={savedListSettings}
        handleUpdateCredentials={handleUpdateCredentials}
        handleOpenFastTrackModal={handleOpenFastTrackModal}
        creditSubmissionsRefetch={creditSubmissionsRefetch}
        filteredCreditSubmissions={filteredCreditSubmissions}
        handleOpenLenderDetailModalView={handleOpenLenderDetailModalView}
      />
    ),
  };

  return (
    <CardContainer
      title="Manage Submissions"
      contentViewType={contentViewType}
      actionBtn={
        <>
          {hasEngsLenderProfile && (
            <Button size="small" color="primary" onClick={handleOpenEngsMappingModal}>
              See ENGS Mapping
            </Button>
          )}
        </>
      }
    >
      {hasEngsLenderProfile && (
        <Modal open={openEngsMappingModal} onClose={handleEngsMappingModalClose} aria-labelledby="ENGS API Mapping">
          <EngsMappingModal vo={vo} creditApp={creditApp} handleEngsMappingModalClose={handleEngsMappingModalClose} />
        </Modal>
      )}

      <LenderSubmission
        vo={vo}
        vp={vp}
        lp={lp}
        account={account}
        submitting={loading}
        creditApp={creditApp}
        equipmentData={equipmentData}
        handleOpenModal={handleOpenModal}
        refetch={creditSubmissionsRefetch}
        creditSubmissions={creditSubmissions}
        submissionMessage={submissionMessage}
        portalConfiguration={portalConfiguration}
        handleSubmissionMessage={handleSubmissionMessage}
        handleUpdateCredentials={handleUpdateCredentials}
        setInvoiceEquipmentTabPage={setInvoiceEquipmentTabPage}
        setOpenEquipmentInputModal={setOpenEquipmentInputModal}
        callbackSubmissionFinished={callbackSubmissionFinished}
        handleSubmitToLenderByProfileId={handleSubmitToLenderByProfileId}
        lenderSubmitting={filteredCreditSubmissions[filteredCreditSubmissions.length - 1]}
      />

      {!!creditSubmissions?.length && (
        <div>
          <div style={{ marginBottom: 16 }}>
            <TableSearch
              width="100%"
              searchRef={searchRef}
              setPageNumber={setPageNumber}
              savedListSettings={savedListSettings}
              setSavedListSettings={setSavedListSettings}
            />
          </div>

          {renderByViewType[contentViewType]}
        </div>
      )}

      {enableFastTrack && (
        <div className={classes.fastTrackSubmissionBanner} onClick={e => handleOpenFastTrackModal(e)}>
          <img src={rocketFastTrack} width="30px" height="30px" alt="Fast track icon" />
          <div className={classes.fastTrackTextContent}>
            This application has already been approved and is on the{" "}
            <span className={classes.fastTrackText}>fast track!</span>
          </div>
        </div>
      )}

      {creditSubLenderSubmissionDetailModal && (
        <LenderSubmissionDetailModal
          vp={vp}
          vo={vo}
          account={account}
          key={Math.random()}
          creditApp={creditApp}
          userProfile={userProfile}
          open={openLenderSubmissionDetailModal}
          relatedCreditSubmissions={creditSubmissions}
          refetchCreditSubmissions={creditSubmissionsRefetch}
          creditSubmission={creditSubLenderSubmissionDetailModal}
          handleCloseLenderDetailModalView={handleCloseLenderDetailModalView}
        />
      )}

      <UpdateLenderCredentials
        lenderProfile={selectedLenderProfile}
        open={openUpdateLenderCredentialsModal}
        handleClose={handleCloseUpdateLenderCredentialsModal}
      />
    </CardContainer>
  );
};

const mapStateToProps = (state: any) => ({
  vo: state.vo,
  account: state.account,
  userProfile: state.userProfile,
  lp: state.vp.lenderProfiles,
  refetch: state.refetch,
  vp: state.vp,
  creditApp: state.creditApp,
  creditSubmissions: state.creditSubmissions,
});

const mapDispatchToProps = {
  setRefetch,
  setValue,
};

export default connect(mapStateToProps, mapDispatchToProps)(LenderViewTable);

const useStyles = makeStyles(theme => ({
  wrapper: {
    margin: theme.spacing(1),
    position: "relative",
  },
  wrapperLoader: {
    width: "42px",
    height: "42px",
    position: "absolute",
    top: 6,
    left: "-45px",
  },
  iconStyles: {
    margin: "0 !important",
  },
  cardBody: {
    paddingTop: 0,
    "& .-pagination": {
      padding: "30px 0 3px",
      alignItems: "center",
    },
    "& .-btn": {
      opacity: "1 !important",
      fontSize: "12px !important",
      fontWeight: 400,
      color: "#000000 !important",
      textTransform: "uppercase",
      fontFamily: "Roboto",
      maxWidth: "125px",
      maxHeight: "30px",
      height: "30px !important",
    },
    "& .-next": {
      "& .-btn": {
        display: "inline-block",
        float: "right",
      },
    },
    "& .-center": {
      flex: "5.5 1 !important",
      justifyContent: "space-between !important",
    },
    "& .-pageInfo": {
      width: "50% !important",
      margin: "0 !important",
    },
    "& .-pageJump": {
      width: "80% !important",
    },
    "& .-pageSizeOptions": {
      width: "50% !important",
      margin: "0 !important",
      "& select": {
        width: "80% !important",
      },
    },
  },
  cardTitle: {
    fontSize: 26,
    lineHeight: "30px",
    fontWeight: 300,
  },
  fastTrackSubmissionBanner: {
    marginTop: "1px",
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
    padding: 10,
    width: "fit-content",
    backgroundColor: "#cbf7d5",
    "&:hover": {
      filter: "drop-shadow(0 0 2px #cbf7d5)",
    },
  },
  fastTrackTextContent: {
    fontWeight: "bold",
    marginLeft: 10,
  },
  fastTrackText: {
    textTransform: "uppercase",
    color: theme.palette.primary.main,
  },
}));
