import React from "react";
import { TextField, MenuItem, TextFieldProps } from "@material-ui/core";
import { SelectOption } from "../../global";

interface FormInputSelectProps<
  ValueType extends string | number = string,
  Options extends SelectOption<ValueType> = SelectOption<ValueType>
> extends Omit<TextFieldProps, "select" | "onChange"> {
  label?: string;
  errorText?: string;
  options: Options[];
  onChange: (value: ValueType) => void;
}

export const FormInputSelect = <
  ValueType extends string | number = string,
  Options extends SelectOption<ValueType> = SelectOption<ValueType>
>({
  label,
  options,
  value,
  onChange,
  error,
  errorText,
  ...rest
}: FormInputSelectProps<ValueType, Options>) => {
  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    event.preventDefault();
    event.stopPropagation();

    onChange(event.target.value as ValueType);
  };

  return (
    <TextField
      select
      fullWidth
      label={label}
      value={value}
      error={error}
      variant="standard"
      onChange={handleChange}
      helperText={error ? errorText : ""}
      {...rest}
    >
      {options.map(option => (
        <MenuItem key={option.value} value={option.value}>
          {option.label}
        </MenuItem>
      ))}
    </TextField>
  );
};
