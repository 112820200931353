import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { gql } from "apollo-boost";
import { useMutation, useQuery } from "@apollo/react-hooks";
import { makeStyles } from "@material-ui/core/styles";
import { LinearProgress } from "@material-ui/core";
import Tile from "../../components/Tile/Tile";
import Table from "components/Table";
import TableHeader from "components/Table/TableHeader";
import TableSearch from "components/Table/TableSearch";
import Button from "../../components/CustomButtons/Button";
import DeleteIcon from "@material-ui/icons/Delete";
import ConfirmationDialog from "components/ConfirmationDialog";
import { ConfigurableItemActions } from "../PortalConfigurationWizard/CreditAppDetailModal/ConfigurableItemActions";
import { AddOutlined } from "@material-ui/icons";
import CreateEditProposalMenuModal from "./CreateEditProposalMenuModal";
import { checkIfUserAbleToSeeProposalMenuConstructor } from "../../../src/services/proposalsService";
import { format } from "date-fns";
import { adminRoles, portalConfigurationTypes } from "pages/Prequal/constants";
import _ from "lodash";

const QUERY_PROPOSAL_MENUS = gql`
  query {
    proposalMenus(input: {}) {
      proposalMenuId
      name
      description
      createdDateTime
      modifiedDateTime
    }
  }
`;

const UPDATE_PROPOSAL_MENU = gql`
  mutation($input: UpdateProposalMenuInput) {
    updateProposalMenu(input: $input) {
      proposalMenuId
    }
  }
`;

const styles = theme => ({
  root: {
    margin: "40px 20px 0 20px",
  },
  proposalMenusTable: {
    "& .rt-tr:not(.-padRow) .rt-td:not(.actions-column)": {
      cursor: "pointer",
    },
    "& .actions-column": {
      display: "flex",
    },
  },
  proposalPackageRoot: {
    backgroundColor: "white",
  },
  actionIcon: {
    cursor: "pointer",
    color: theme.palette.primary.main,
  },
});

const useStyles = makeStyles(styles);

const ProposalMenuConstructor = props => {
  const classes = useStyles();
  const history = useHistory();

  const userProfile = useSelector(state => state.userProfile);
  const vendorProfile = useSelector(state => state.vp);
  const portalConfiguration = useSelector(state => state.portalConfiguration);

  const searchRef = useRef({ prevSearch: undefined, currentSearch: "" });
  const [pageNumber, setPageNumber] = useState(0);
  const [pageSizeValue, setPageSizeValue] = useState(5);
  const [savedListSettings, setSavedListSettings] = useState({ page: 0, pageSize: 20 });

  const [proposalMenus, setProposalMenus] = useState([]);
  const [isUserAbleToSeeProposalMenuConstructor, setIsUserAbleToSeeProposalMenuConstructor] = useState(
    checkIfUserAbleToSeeProposalMenuConstructor(userProfile, vendorProfile)
  );
  const [isCreateEditProposalMenuModalOpen, setIsCreateEditProposalMenuModalOpen] = useState(false);
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
  const [proposalMenuToEdit, setProposalMenuToEdit] = useState();

  const { data: proposalMenusData, loading: queryingLoading, refetch: refetchProposalMenus } = useQuery(
    QUERY_PROPOSAL_MENUS,
    {
      fetchPolicy: "no-cache",
    }
  );

  const [updateProposalMenu, { loading: updateLoading }] = useMutation(UPDATE_PROPOSAL_MENU, {
    context: { authRequired: true },
    onCompleted() {
      refetchProposalMenus();
    },
  });

  let columns = [
    {
      Header: "Name",
      accessor: "name",
    },
    {
      Header: "Description",
      accessor: "description",
    },
    {
      Header: "Created",
      accessor: "createdDateTime",
      Cell: ({ value }) => (value ? format(new Date(value), "M/dd/yyyy") : ""),
    },
    {
      Header: "Modified",
      accessor: "modifiedDateTime",
      Cell: ({ value }) => (value ? format(new Date(value), "M/dd/yyyy") : ""),
    },
  ];

  const [canWrite, setCanWrite] = useState(
    _.get(
      portalConfiguration,
      `${portalConfigurationTypes.aftermarket}.config.rolesSettings.assignedOnly`,
      []
    ).includes(userProfile.vendorContactRole)
  );
  const [canRead, setCanRead] = useState(
    _.get(portalConfiguration, `${portalConfigurationTypes.aftermarket}.config.rolesSettings.readOnly`, []).includes(
      userProfile.vendorContactRole
    )
  );
  const [isSuperAdmin, setIsSuperAdmin] = useState(false);
  useEffect(() => {
    if (portalConfiguration && userProfile) {
      setIsSuperAdmin([adminRoles.super, adminRoles.singleAccountOnly].includes(userProfile.adminRole));
      setCanWrite(
        _.get(
          portalConfiguration,
          `${portalConfigurationTypes.aftermarket}.config.rolesSettings.assignedOnly`,
          []
        ).includes(userProfile.vendorContactRole)
      );
      setCanRead(
        _.get(
          portalConfiguration,
          `${portalConfigurationTypes.aftermarket}.config.rolesSettings.readOnly`,
          []
        ).includes(userProfile.vendorContactRole)
      );
    }
  }, [portalConfiguration, userProfile]);

  useEffect(() => {
    if (_.isEmpty(proposalMenusData)) {
      return;
    }
    setProposalMenus(_.orderBy(proposalMenusData.proposalMenus, "createdDateTime"));
  }, [proposalMenusData]);

  if (isSuperAdmin === true || canWrite === true) {
    columns.push({
      Header: "Actions",
      Cell: props => (
        <ConfigurableItemActions
          itemType="Menu"
          onEdit={e => handleEditMenuClick(e, props.original)}
          onRemove={e => handleRemoveMenuClick(e, props.original)}
          onRemoveCustomIcon={DeleteIcon}
        />
      ),
      width: 150,
      getProps: (state, rowInfo) => {
        return {
          className: "actions-column",
        };
      },
    });
  }

  const handleCreateMenuClick = () => {
    const emptyProposalMenu = {
      name: "",
      description: "",
    };
    setProposalMenuToEdit(emptyProposalMenu);
    setIsCreateEditProposalMenuModalOpen(true);
  };

  const handleEditMenuClick = (e, proposalMenuToEdit) => {
    e.stopPropagation();
    const proposalMenuToEditCopy = _.cloneDeep(proposalMenuToEdit);
    setProposalMenuToEdit(proposalMenuToEditCopy);
    setIsCreateEditProposalMenuModalOpen(true);
  };

  const handleRemoveMenuClick = (e, proposalMenuToRemove) => {
    e.stopPropagation();
    setProposalMenuToEdit(proposalMenuToRemove);
    setIsConfirmationModalOpen(true);
  };

  const onConfiramtionModalClose = (event, reason) => {
    if (_.includes(["backdropClick", "escapeKeyDown"], reason)) {
      return;
    }

    setIsConfirmationModalOpen(false);
    if (event.result === "ok") {
      updateProposalMenu({
        variables: {
          input: {
            proposalMenuId: proposalMenuToEdit.proposalMenuId,
            archived: true,
          },
        },
      });
    }
  };

  const handleProposalMenuRowClick = rowInfo => {
    history.push(`/aftermarket/menu/${rowInfo.original.proposalMenuId}`);
  };

  const loading = queryingLoading || updateLoading;
  return (
    <div className={classes.root}>
      {loading && <LinearProgress />}

      {!loading && !isUserAbleToSeeProposalMenuConstructor && <h3>Access Denied</h3>}

      {!loading && isUserAbleToSeeProposalMenuConstructor && (
        <Tile>
          <TableHeader
            filterComps={
              <TableSearch
                setPageNumber={setPageNumber}
                setSavedListSettings={setSavedListSettings}
                searchRef={searchRef}
                savedListSettings={savedListSettings}
              />
            }
          >
            <Button
              size="sm"
              color="primary"
              onClick={handleCreateMenuClick}
              disabled={isSuperAdmin === false && canWrite === false}
            >
              <AddOutlined />
              Create Menu
            </Button>
          </TableHeader>
          <Table
            getTrProps={(state, rowInfo, column, instance) => ({
              onClick: () => {
                if (isSuperAdmin === true || canWrite === true) {
                  handleProposalMenuRowClick(rowInfo);
                }
              },
            })}
            color="primary"
            data={proposalMenus}
            columns={columns}
            pageSize={pageSizeValue}
            pageNumber={pageNumber}
            defaultPageSize={savedListSettings.pageSize}
            showPaginationTop={false}
            showPaginationBottom={true}
            onPageChange={changePage => {
              setPageNumber(changePage);
            }}
            onPageSizeChange={changePageSize => {
              setPageSizeValue(changePageSize);
              setPageNumber(0);
            }}
            className={classes.proposalMenusTable + " -highlight"}
          />
        </Tile>
      )}

      <CreateEditProposalMenuModal
        isOpen={isCreateEditProposalMenuModalOpen}
        setIsOpen={setIsCreateEditProposalMenuModalOpen}
        proposalMenu={proposalMenuToEdit}
        setProposalMenu={setProposalMenuToEdit}
        refetchProposalMenus={refetchProposalMenus}
      />

      <ConfirmationDialog
        title="Confirmation"
        message="Are you sure you want to remove Menu?"
        open={isConfirmationModalOpen}
        onClose={onConfiramtionModalClose}
      />

      {/*       <Snackbar
        open={savePortalConfigurationSuccess}
        autoHideDuration={3000}
        onClose={() => setSavePortalConfigurationSuccess(state => !state)}
      >
        <Alert severity="success">
          <AlertTitle>Changes Saved!</AlertTitle>
        </Alert>
      </Snackbar>
      <Snackbar
        open={savePortalConfigurationError}
        autoHideDuration={3000}
        onClose={() => setSavePortalConfigurationError(state => !state)}
      >
        <Alert severity="error">
          <AlertTitle>Error on saving</AlertTitle>
        </Alert>
      </Snackbar> */}
    </div>
  );
};

export default ProposalMenuConstructor;
