import React, { useCallback, useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useMutation, useQuery } from "@apollo/react-hooks";
import EditIcon from "@material-ui/icons/Edit";
import Button from "components/CustomButtons/Button.js";
import CreditAppModifierConfirmModal from "./CreditAppModifierConfirmModal";
import { gql } from "apollo-boost";
import { useSelector } from "react-redux";
import Tile from "components/Tile/Tile";
import ConfirmationDialog from "components/ConfirmationDialog";
import iconPlusSvg from "../../assets/img/icons/plus.svg";
import iconMinusSvg from "../../assets/img/icons/minus.svg";
import classNames from "classnames";
import CreditAppModalEditableInput from "./CreditAppModalEditableInput";
import {
  checkIfIndividualCreditApp,
  formatCurrency,
  formatPercentage,
  formatPhoneNumber,
  formatSSN,
} from "../../utils";
import {
  blockTypes,
  getCreditAppHTMLForPDF,
  getDateTimeDisplayValue,
  isItemHasRestrictionForRole,
  refSettingsByRefType,
  sectionTypes,
  uneditableFieldKeys,
  uneditableFieldTypes,
  getSignatureTaskForContact,
} from "../../services/creditAppModalService";
import { rolesSettingsKeys } from "../../services/pcwService";
import { buildTree, getDropdownTreeSelectDefaultOptions } from "../../services/locationsService";
import { adminRoles, creditAppTypes, signatureDateKeys, taskRegardingValues } from "../Prequal/constants";

import _ from "lodash";
import axios from "axios";
import { DCR_API_BASE_SERVER_URL } from "config";
import { Check, Info, Send } from "@material-ui/icons";
import { Dialog, DialogActions, DialogContent, DialogTitle, Typography } from "@material-ui/core";
import Close from "@material-ui/icons/Close";
import { Q_CREDIT_APP } from "../../queries/common";

const useStyles = makeStyles(theme => ({
  root: {
    "& .MuiInput-underline:focus-within::after": {
      borderColor: theme.palette.primary.main,
    },
    "& .MuiInput-underline.Mui-error:focus-within::after": {
      borderColor: "#f44336",
    },
    "& .MuiInput-underline.Mui-error::after": {
      borderColor: "#ededed",
    },
    "& .locations-tree-view-dropdown": {
      width: "100%",
    },
  },
  modalStyle: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    minWidth: 680,
    "@media (max-width: 768px)": {
      width: "96vw",
      minWidth: "96vw",
    },
  },
  fieldRow: {
    minWidth: 500,
    display: "flex",
    "@media (max-width: 768px)": {
      minWidth: "80vw",
    },
  },
  fieldTitle: {
    fontWeight: "bold",
  },
  detailsCell: {
    padding: "5px",
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  detailsLeftCol: {
    width: "50%",
  },
  detailsRightCol: {
    width: "50%",
  },
  inputSlim: {
    margin: 0,
    padding: 0,
  },
  blocks: {
    position: "relative",
  },
  sectionTitle: {
    position: "relative",
  },
  collapseButton: {
    position: "absolute",
    top: 0,
    right: 0,
    width: 50,
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontSize: 20,
    zIndex: 3,
    cursor: "pointer",
    color: "#999",
  },
  icon: {
    width: 20,
    height: 20,
    backgroundRepeat: "no-repeat",
    backgroundSize: "contain",
    backgroundPosition: "center",
    display: "inline-block",
    opacity: 0.3,
  },
  iconPlus: {
    backgroundImage: `url("${iconPlusSvg}")`,
  },
  iconMinus: {
    backgroundImage: `url("${iconMinusSvg}")`,
  },
  circularProgressWrapper: {
    display: "flex",
    justifyContent: "center",
  },
  tileSubtitle: {
    color: "#3C4858",
    "&:first-of-type": {
      margin: "-15px 0 0",
    },
  },
  authorizationLinkSent: {
    display: "flex",
    alignItems: "center",
  },
}));

const UPSERT_TASK = gql`
  mutation($input: UpsertTaskInput!) {
    upsertTask(input: $input)
  }
`;

const UPDATE_CREDIT_APPLICATION_V2_QUERY = gql`
  mutation($creditAppId: ID!, $input: UpdateCreditApplicationV2Input!) {
    updateCreditApplicationV2(creditAppId: $creditAppId, input: $input)
  }
`;

const UPDATE_ACCOUNT_QUERY = gql`
  mutation($dynamicsId: ID, $fieldsObj: UpdateAccountFields) {
    updateAccount(dynamicsId: $dynamicsId, fieldsObj: $fieldsObj)
  }
`;

const UPDATE_ACCOUNT_CRM_QUERY = gql`
  mutation($entityId: ID!, $fieldsObj: UpdateAccountCRMFields) {
    updateAccountCRM(entityId: $entityId, fieldsObj: $fieldsObj)
  }
`;

const ASSIGN_SALES_REP = gql`
  mutation($fieldsObj: AssignSalesRepInput!, $entityId: ID!, $VOId: ID!) {
    assignSalesRep(fieldsObj: $fieldsObj, entityId: $entityId, VOId: $VOId)
  }
`;

const UPDATE_DYNAMICS_VO_LOCATION = gql`
  mutation($fieldsObj: UpdateVendorOpportunityCRMFields, $entityId: ID!) {
    updateVendorOpportunityCRM(fieldsObj: $fieldsObj, entityId: $entityId)
  }
`;

const UPDATE_VO_LOCATION = gql`
  mutation($fieldsObj: UpdateVendorOpportunityInput!, $VOId: String!) {
    updateVendorOpportunity(fieldsObj: $fieldsObj, VOId: $VOId)
  }
`;

const GET_LOCATIONS = gql`
  query locations($accountId: ID) {
    locations(accountId: $accountId) {
      locationId
      locationName
      category
      accountDynamicsId
      ocaContactId
      ocaContact {
        fullName
      }
      contactIds
      locationParentId
      notificationAppReceipt
    }
  }
`;

const GET_SALES_REPS_FOR_ADMIN = gql`
  query($accountId: ID) {
    salesRepsForAdmin(accountId: $accountId) {
      id
      dynamicsContactId
      email
      firstName
      lastName
      fullName
      phoneNumber
      mugshot
      availability
      vendorContactRole
      title
    }
  }
`;

// Credit Application Detail Modal v2
export default function CADMv2(props) {
  const {
    vo,
    account,
    vendorProfile,
    equipmentData,
    creditAppModalConfig,
    vendorOpportunityTasks = [],
    isEditingDisabled,

    isForPDFOnly,
    setIsPDFDownloaded,

    isForHTMLOnly,
    setHTMLForPDF,

    refetchVO,
    refetchTasks,
    refetchEquipment,
    refetchCreditAppOuter, // to update credit app data outside of CADM
  } = props;
  const classes = useStyles();
  const userProfile = useSelector(state => state.userProfile);
  const [detailsStateToSend, setDetailsStateToSend] = useState(null);
  const [creditAppModalData, setCreditAppModalData] = useState(null);
  const [creditAppUpdateIsSubmitting, setCreditAppUpdateIsSubmitting] = useState(false);
  const [locations, setLocations] = useState(null);
  const [locationsTree, setLocationsTree] = useState(null);
  const [locationsTreeOptions, setLocationsTreeOptions] = useState(null);
  const [salesReps, setSalesReps] = useState(null);
  const [isEditMode, setIsEditMode] = useState(false);
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const [sectionsWrapperEl, setSectionsWrapperEl] = useState(null);
  const [isIndividualCreditApp, setIsIndividualCreditApp] = useState(null);
  const [isSignatureDateWasUpdated, setIsSignatureDateWasUpdated] = useState(false);
  const [isAuthLinkModalOpen, setIsAuthLinkModalOpen] = useState(false);
  const [creatingAuthLinkTask, setCreatingAuthLinkTask] = useState(false);
  const [taskCreated, setTaskCreated] = useState(false);
  const [authLinkOwner, setAuthLinkOwner] = useState({});
  const [voDetilsToUpdate, setVoDetilsToUpdate] = useState();
  const [isNeedToRefetchEquipmentData, setIsNeedToRefetchEquipmentData] = useState(false);
  const [isCreditAppConvertTypeConfirmationModalOpen, setIsCreditAppConvertTypeConfirmationModalOpen] = useState(false);
  const [isCreditAppConvertTypeLoading, setIsCreditAppConvertTypeLoading] = useState(false);

  const [detailsState, setDetailsState] = useState({});

  const { vendorContactRole: userRole, adminRole: userAdminRole } = userProfile;

  const handleEditableInputUpdate = (field, val, block, blockIdx) => {
    let fieldKey = field.key;

    const isDynamicReferenceField = _.get(block, "type") === blockTypes.dynamicReference;
    const isEquipmentField = _.get(block, "type") === blockTypes.equipment;

    const keysSameForCreditAppAndPOC = ["firstName", "middleName", "lastName", "nameSuffix", "email", "phone"];
    const isPrimaryContactField = _.includes(fieldKey, "primaryContact.");
    const isNeedToProcessAsOwners =
      _.includes([blockTypes.coApplicant, blockTypes.owner], block.type) || isPrimaryContactField;

    if (isNeedToProcessAsOwners) {
      detailsState.owners = detailsState.owners || [];
      fieldKey = field.key.slice(field.key.lastIndexOf(".") + 1);
      if (isPrimaryContactField && _.includes(keysSameForCreditAppAndPOC, fieldKey)) {
        detailsState[fieldKey] = val; // to update credit app as well
      }

      let ownerPgId;
      if (isPrimaryContactField) {
        ownerPgId = _.get(creditAppModalData, `primaryContact.ownerPgId`);
      } else {
        const ownerIdx = block.type === blockTypes.coApplicant ? 1 : blockIdx;
        ownerPgId = _.get(creditAppModalData, `owners[${ownerIdx}].ownerPgId`);
      }

      let ownerToUpdate = _.find(detailsState.owners, { ownerPgId });
      if (!ownerToUpdate) {
        ownerToUpdate = { ownerPgId, creditApplicationId: creditAppModalData.id };
        detailsState.owners.push(ownerToUpdate);
      }
      ownerToUpdate[fieldKey] = val;
    } else if (isDynamicReferenceField) {
      const originalReference = _.find(creditAppModalData.references, { referenceIdx: block.referenceIdx });
      const alreadyUpdatedRef = _.find(detailsState.references, { referenceIdx: block.referenceIdx });
      const fieldKey = _.get(field, "ocaConfig.config.fieldName", null);
      if (alreadyUpdatedRef) {
        alreadyUpdatedRef[fieldKey] = val;
      } else {
        const refToUpsert = {
          referenceId: _.get(originalReference, "referenceId", undefined),
          referenceIdx: _.get(originalReference, "referenceIdx", block.referenceIdx),
          [fieldKey]: val,
        };
        detailsState.references = detailsState.references
          ? _.concat(detailsState.references, refToUpsert)
          : [refToUpsert];
      }
    } else if (isEquipmentField) {
      const originalEquipment = _.find(creditAppModalData.equipments, { equipmentIdx: block.equipmentIdx });
      const alreadyUpdatedEquipment = _.find(detailsState.equipments, { equipmentIdx: block.equipmentIdx });
      const fieldKey = _.get(field, "ocaConfig.config.fieldName", null);
      if (alreadyUpdatedEquipment) {
        alreadyUpdatedEquipment[fieldKey] = val;
      } else {
        const equipmentToUpsert = {
          equipmentId: _.get(originalEquipment, "equipmentId", undefined),
          equipmentIdx: _.get(originalEquipment, "equipmentIdx", block.equipmentIdx),
          [fieldKey]: val,
        };
        detailsState.equipments = detailsState.equipments
          ? _.concat(detailsState.equipments, equipmentToUpsert)
          : [equipmentToUpsert];
      }
    } else {
      detailsState[fieldKey] = val;
    }

    setDetailsState(detailsState);
  };

  const toggleCollapse = sectionId => setCollapseState({ ...collapseState, [sectionId]: !collapseState[sectionId] });

  const [upsertTask] = useMutation(UPSERT_TASK, {
    context: { authRequired: true },
  });

  const [updateCreditAppRequest, { loading: updateCreditAppLoading }] = useMutation(
    UPDATE_CREDIT_APPLICATION_V2_QUERY,
    {
      context: { authRequired: true },
    }
  );

  const [updateAccountRequest] = useMutation(UPDATE_ACCOUNT_QUERY, {
    context: { authRequired: true },
  });

  const [updateAccountCRMRequest] = useMutation(UPDATE_ACCOUNT_CRM_QUERY, {
    context: { authRequired: true },
  });

  const [updateSalesRep] = useMutation(ASSIGN_SALES_REP, {
    context: { authRequired: true },
  });

  const [updateDynamicsVOLocation] = useMutation(UPDATE_DYNAMICS_VO_LOCATION, {
    context: { authRequired: true },
  });

  const [updateVOLocation] = useMutation(UPDATE_VO_LOCATION, {
    context: { authRequired: true },
  });

  const { data: creditAppModalDataResponse, loading: creditAppLoading, refetch: creditAppModalDataRefetch } = useQuery(
    Q_CREDIT_APP,
    {
      variables: {
        VOId: vo.vendorOpportunityId,
      },
      fetchPolicy: "no-cache",
      nextFetchPolicy: "no-cache",
    }
  );

  const { data: locationDataResponse, loadingLocations, refetch: locationsRefetch } = useQuery(GET_LOCATIONS, {
    context: { authRequired: true },
    variables: {
      accountId: account.id,
    },
  });

  const { data: salesRepsDataResponse, loadingSalesReps } = useQuery(GET_SALES_REPS_FOR_ADMIN, {
    context: { authRequired: true },
    fetchPolicy: "no-cache",
    variables: {
      accountId: account.id,
    },
  });

  useEffect(() => {
    if (!creditAppModalDataResponse) {
      return;
    }
    const disclosureToSet = _.get(creditAppModalDataResponse, "creditApplication.disclosure")
      ? JSON.parse(creditAppModalDataResponse.creditApplication.disclosure)
      : null;
    setCreditAppModalData({
      ...creditAppModalDataResponse.creditApplication,
      owners: creditAppModalDataResponse.creditApplication.creditApplicationOwner, // to have strict mappings
      equipments: _.get(equipmentData, "equipments"),
      disclosure: disclosureToSet,
    });
    setIsIndividualCreditApp(
      checkIfIndividualCreditApp(_.get(creditAppModalDataResponse, "creditApplication.applicationType"))
    );
  }, [creditAppModalDataResponse, equipmentData]);

  useEffect(() => {
    if (!locationDataResponse) {
      return;
    }
    setLocations(locationDataResponse.locations);
  }, [locationDataResponse]);

  useEffect(() => {
    if (_.isEmpty(creditAppModalData) || _.isEmpty(locations)) {
      return;
    }
    const locationsOptions = _.map(locations, location => {
      return {
        ...location,
        isDefaultValue: _.isArray(creditAppModalData.locationId)
          ? _.includes(creditAppModalData.locationId, location.locationId)
          : creditAppModalData.locationId === location.locationId,
      };
    });
    setLocationsTree(buildTree(locationsOptions));
  }, [creditAppModalData, locations]);

  useEffect(() => {
    if (_.isEmpty(salesRepsDataResponse) || _.isEmpty(creditAppModalConfig)) {
      return;
    }
    const repField = _(creditAppModalConfig.sections)
      .flatMap("blocks")
      .flatMap("fields")
      .find({ key: "vendorContactId" });

    if (!repField || !repField.ocaConfig) {
      return;
    }
    repField.ocaConfig.config.fieldOptions = _.map(salesRepsDataResponse.salesRepsForAdmin, rep => {
      const repLabel = `${rep.fullName} (${rep.email})`;
      return {
        optionLabel: repLabel,
        optionValue: rep.id,
        displayLabel: {
          en: repLabel,
          es: repLabel,
        },
      };
    });
    setSalesReps(salesRepsDataResponse.salesRepsForAdmin);
  }, [salesRepsDataResponse, creditAppModalConfig]);

  useEffect(() => {
    if (!sectionsWrapperEl) {
      return;
    }
    if (isForPDFOnly) {
      handleCreditAppPdfRequest();
    }
    if (isForHTMLOnly) {
      const html = getCreditAppHTMLForPDF(
        sectionsWrapperEl.innerHTML,
        creditAppModalData,
        vendorProfile,
        account,
        userProfile,
        isIndividualCreditApp
      );
      setHTMLForPDF(html);
    }
  }, [sectionsWrapperEl]);

  const sectionsWrapperRef = useCallback(node => {
    if (node !== null) {
      setSectionsWrapperEl(node);
    }
  }, []);

  const collapseInitialState = _.reduce(
    creditAppModalConfig.sections,
    (result, section) => {
      result[section.id] = _.get(section, "config.isCollapsed", false);
      return result;
    },
    {}
  );
  const [collapseState, setCollapseState] = useState(collapseInitialState);

  const getFieldDisplayValue = field => {
    const getDisplayValueFromSelectOption = selectOption => {
      return _.get(selectOption, "displayLabel.en", "")
        ? _.get(selectOption, "displayLabel.en", "")
        : _.get(selectOption, "optionLabel", "");
    };
    const getSelectFieldDisplayValue = field => {
      const fieldOptions = _.get(field, "ocaConfig.config.fieldOptions", []);
      const selectedOption = _.find(fieldOptions, option => {
        let valuesToFind = [field.value];
        if (field.value === true) {
          valuesToFind = ["true", "yes", true];
        }
        if (field.value === false) {
          valuesToFind = ["false", "no", false];
        }
        return _.includes(valuesToFind, option.optionValue) || _.includes(valuesToFind, option.optionLabel);
      });
      return getDisplayValueFromSelectOption(selectedOption);
    };

    const getRadioQuestionFieldDisplayValue = field => {
      const fieldOptions = _.get(field, "ocaConfig.config.valuesList", []);
      const selectedOption = _.find(fieldOptions, { value: field.value });
      return getDisplayValueFromSelectOption(selectedOption);
    };

    const getMultiSelectFieldDisplayValue = field => {
      const fieldOptions = _.get(field, "ocaConfig.config.fieldOptions", []);
      const selectedOptions = _.filter(fieldOptions, option => _.includes(field.value, option.optionValue));
      return _(selectedOptions)
        .map(getDisplayValueFromSelectOption)
        .compact()
        .join(", ");
    };

    const getTaxIdFieldDisplayValue = field => {
      const TAX_ID_LENGTH = 9;
      return field.value
        ? field.value
            .toString()
            .replace(/[^0-9]/g, "")
            .padStart(TAX_ID_LENGTH, "0")
            .slice(0, 2) +
            "-" +
            field.value
              .toString()
              .replace(/[^0-9]/g, "")
              .padStart(TAX_ID_LENGTH, "0")
              .slice(2)
        : "";
    };

    const getLocationDisplayValue = field => {
      const location = _.find(locations, { locationId: field.value });
      return _.get(location, "locationName");
    };

    const getHtmlOutputFieldDisplayValue = field => {
      return field.selectedApiId ? _.get(creditAppModalData, field.selectedApiId, null) : field.customMarkup;
    };

    const isMaskedField = field.value && isItemHasRestrictionForRole(field, rolesSettingsKeys.masked, userRole);
    if (isMaskedField) {
      return `*Access Restricted*`;
    }

    const getDisplayValueByFieldTypeHashMap = {
      "select-field": getSelectFieldDisplayValue,
      "multiselect-field": getMultiSelectFieldDisplayValue,
      "radio-question": getRadioQuestionFieldDisplayValue,
      "amount-field": field => formatCurrency(field.value, true),
      "percent-field": field => formatPercentage(field.value),
      "taxid-field": getTaxIdFieldDisplayValue,
      "location-select": getLocationDisplayValue,
      "representative-select": getSelectFieldDisplayValue,
      "phone-field": field => formatPhoneNumber(field.value),
      "socialsecurity-field": field => formatSSN(field.value),
      "html-output-field": getHtmlOutputFieldDisplayValue,
    };
    const getDisplayValueByFieldNameHashMap = {
      signatureDate: field => (field.value ? getDateTimeDisplayValue(field.value) : "Not Signed"),
      personalGuaranteeSignatureDate: field => (field.value ? getDateTimeDisplayValue(field.value) : "Not Signed"),
    };
    const fieldName = _.get(field, "ocaConfig.config.fieldName", null);
    const fieldType = _.get(field, "ocaConfig.type", null);

    if (getDisplayValueByFieldNameHashMap[fieldName]) {
      return getDisplayValueByFieldNameHashMap[fieldName](field);
    }
    if (getDisplayValueByFieldTypeHashMap[fieldType]) {
      return getDisplayValueByFieldTypeHashMap[fieldType](field);
    }
    return field.value === 0 ? _.toString(field.value) : field.value;
  };

  const getMonthFromDateByKey = key => {
    const date = _.get(creditAppModalData, key, null);
    return date ? date.slice(0, 2) : null;
  };
  const getYearFromDateByKey = key => {
    const date = _.get(creditAppModalData, key, null);
    return date ? _.parseInt(date.slice(6, 10)) : null;
  };

  const getFieldValue = (field, block, blockIdx) => {
    const combinedFieldKeysHashMap = {
      monthBusinessEstablished: () => getMonthFromDateByKey("dateEstablished"),
      yearBusinessEstablished: () => getYearFromDateByKey("dateEstablished"),
      monthBusinessIncorporated: () => getMonthFromDateByKey("dateIncorporated"),
      yearBusinessIncorporated: () => getYearFromDateByKey("dateIncorporated"),
    };

    let defaultFieldValue;
    if (!block.type || block.type === blockTypes.coApplicant) {
      defaultFieldValue = _.isFunction(combinedFieldKeysHashMap[field.key])
        ? combinedFieldKeysHashMap[field.key]()
        : _.get(creditAppModalData, field.key, null);
    }
    if (block.type === blockTypes.reference) {
      const refSettings = refSettingsByRefType[block.referenceType];
      const refObject = _.find(creditAppModalData.references, {
        referenceType: refSettings.referenceType,
        referenceIdx: null,
      });
      const key = _.findKey(refSettings.mappings, key => key === field.key);
      defaultFieldValue = _.get(refObject, key, null);
    }
    if (block.type === blockTypes.dynamicReference) {
      const refObject = _.find(creditAppModalData.references, { referenceIdx: block.referenceIdx });
      const key = _.get(field, "ocaConfig.config.fieldName", null);
      defaultFieldValue = _.get(refObject, key, null);
    }
    if (block.type === blockTypes.equipment) {
      const equipmentObject = _.find(creditAppModalData.equipments, { equipmentIdx: block.equipmentIdx });
      const key = _.get(field, "ocaConfig.config.fieldName", null);
      defaultFieldValue = _.get(equipmentObject, key, null);
    }
    if (block.type === blockTypes.owner) {
      const ownerObject = creditAppModalData.owners[blockIdx];
      defaultFieldValue = _.get(ownerObject, field.key, null);
    }

    if (_.get(field, "ocaConfig.type") === "html-output-field") {
      defaultFieldValue = getFieldDisplayValue(field);
    }

    if (_.includes(signatureDateKeys, field.key) && !block.type) {
      // POC section for business app when POC is not the same person as owner
      defaultFieldValue = _.get(creditAppModalData, `${field.key}`, null);
    }

    if (field.key === "vendorContactId") {
      defaultFieldValue = _.get(vo, "salesRepresentative.id", "");
    }

    return defaultFieldValue;
  };

  const handleAuthConfirm = async owner => {
    setCreatingAuthLinkTask(true);

    const payloadInput = getSignatureTaskForContact(
      owner,
      vo.vendorOpportunityId,
      creditAppModalData.dynamicsId,
      account,
      userProfile
    );

    await upsertTask({
      variables: {
        input: payloadInput,
      },
    });

    refetchTasks();

    setCreatingAuthLinkTask(false);
    setTaskCreated(true);
    setTimeout(() => {
      setIsAuthLinkModalOpen(false);
      setTimeout(() => {
        setAuthLinkOwner({});
        setTaskCreated(false);
        setCreatingAuthLinkTask(false);
      }, 1000);
    }, 3000);
  };

  const handleAuthClick = owner => {
    setIsAuthLinkModalOpen(true);
    setAuthLinkOwner(owner);
  };

  const getFieldRow = (field, block, blockIdx, section) => {
    const isFieldHidden = isItemHasRestrictionForRole(field, rolesSettingsKeys.hidden, userRole);
    if (vo.entityType === "CASH_SALE" && field.key?.includes("signature")) {
      return null;
    }
    if (isFieldHidden) {
      return null;
    }
    if (isEditMode && _.includes(uneditableFieldTypes, _.get(field, "ocaConfig.type"))) {
      return null;
    }
    if (isEditMode && _.includes(uneditableFieldKeys, field.key)) {
      return null;
    }

    if (_.includes(signatureDateKeys, field.key) && !block.type && !isIndividualCreditApp) {
      // assuming it's a POC section for commercial.
      const isPOCOwnerOrOfficer = !creditAppModalData.primaryContact.pcOnly;
      if (isPOCOwnerOrOfficer) {
        return null; // signatures will be shown at ownership section
      }
    }

    if (section.type === sectionTypes.disclosures && !isEditMode) {
      return <div dangerouslySetInnerHTML={{ __html: getFieldValue(field, block, blockIdx) }}></div>;
    }

    field.value = getFieldValue(field, block, blockIdx);
    field.displayValue = getFieldDisplayValue(field);
    if (!isEditMode && !field.displayValue) {
      return null;
    }

    if (_.get(field, "ocaConfig.type") === "html-output-field") {
      return <div dangerouslySetInnerHTML={{ __html: field.displayValue }}></div>;
    }

    const isFieldHiddenOnPDF = isItemHasRestrictionForRole(field, rolesSettingsKeys.hiddenOnPDF, userRole);
    const isFieldReadOnly = isItemHasRestrictionForRole(field, rolesSettingsKeys.readOnly, userRole);
    const isFieldMasked = isItemHasRestrictionForRole(field, rolesSettingsKeys.masked, userRole);
    const isBlockReadOnly = isItemHasRestrictionForRole(block, rolesSettingsKeys.readOnly, userRole);
    const isSectionReadOnly = isItemHasRestrictionForRole(section, rolesSettingsKeys.readOnly, userRole);
    const isReadOnly = _.some([isFieldReadOnly, isFieldMasked, isBlockReadOnly, isSectionReadOnly]);

    //TODO: move all Authorization Link logic in separate function/component
    const isUnsignedField = _.some(signatureDateKeys, k => _.includes(field.key, k)) && !field.value;
    const getOwner = () => {
      if (block.type === blockTypes.owner) {
        return creditAppModalData.owners[blockIdx];
      }
      if (block.type === blockTypes.coApplicant) {
        return creditAppModalData.owners[1];
      }
      return creditAppModalData.primaryContact;
    };

    const owner = getOwner();
    const ownerAuthorizationTaskCreated = _.find(vendorOpportunityTasks, t => {
      return t.ownerPgId === _.get(owner, "ownerPgId") && t.regarding === taskRegardingValues.signatures;
    });
    const isNeedToShowSendAuthLink = isUnsignedField && !ownerAuthorizationTaskCreated;
    ///

    return (
      <div className={classes.fieldRow + " fieldRow" + (isFieldHiddenOnPDF ? " hiddenOnPdf" : "")} key={field.key}>
        <div className={classes.detailsCell + " " + classes.detailsLeftCol + " fieldTitleWrapper"}>
          <span className={classes.fieldTitle + " fieldTitle"}>
            {vo.entityType === "CASH_SALE" ? field.config.title.replace("Financing", "") : field.config.title}
          </span>
        </div>
        <div className={classNames(classes.detailsCell, classes.detailsRightCol, classes.root, "fieldValueWrapper")}>
          {isEditMode && !isReadOnly ? (
            <CreditAppModalEditableInput
              field={field}
              classes={classes}
              onChangeFn={(field, val) => handleEditableInputUpdate(field, val, block, blockIdx)}
              locationsTree={locationsTree}
              locationsTreeOptions={getDropdownTreeSelectDefaultOptions({
                onChange: currentNode => handleEditableInputUpdate(field, currentNode.value, block, blockIdx),
              })}
            />
          ) : (
            <>
              {!isUnsignedField ? (
                <span>{field.displayValue}</span>
              ) : (
                <div style={{ display: "flex", width: "100%", justifyContent: "space-between", alignItems: "center" }}>
                  <span>Not Signed</span>
                  {ownerAuthorizationTaskCreated ? (
                    <span className={classes.authorizationLinkSent + " hiddenOnPdf"}>
                      <Check style={{ fontSize: 20, marginRight: 4, color: "#2e7d32" }} />
                      Authorization Link Sent
                    </span>
                  ) : (
                    <Button
                      color="primary"
                      style={{ padding: 6 }}
                      onClick={() => handleAuthClick(owner)}
                      disabled={!!ownerAuthorizationTaskCreated}
                      className={"hiddenOnPdf"}
                    >
                      <Send />
                      Send Authorization Link
                    </Button>
                  )}
                </div>
              )}
            </>
          )}
        </div>
      </div>
    );
  };

  const getBlocks = section => {
    const getSimpleBlock = (block, blockIdx, section) => {
      return (
        <div key={block.id} className={"blockWrapper"}>
          {block.config.title && <h3 className={"blockTitle"}>{block.config.title}</h3>}
          <div className={"fieldsWrapper"}>
            {block.fields.map(field => getFieldRow(field, block, blockIdx, section))}
          </div>
        </div>
      );
    };

    const getOwnersBlocks = (block, section) => {
      const getOwnerBlockTitle = idx => {
        if (!isIndividualCreditApp) {
          return `Owner ${idx + 1}`;
        }
        return idx === 0 ? "Applicant" : "Co-Applicant";
      };

      const blocksCount = _.size(creditAppModalData.owners);
      const ownersBlocks = _(Array(blocksCount))
        .fill()
        .map(() => _.cloneDeep(block))
        .value();
      return _.map(ownersBlocks, (block, idx) => {
        const isNeedToRenderAddOwnerButton = isEditMode && blocksCount === idx + 1;
        return (
          <Grid container key={`block_${Math.random()}`} className={"blockWrapper"} spacing={2}>
            <Grid item xs={12}>
              <h3 className={"blockTitle"}>{getOwnerBlockTitle(idx)}</h3>
              <div className={"fieldsWrapper"}>
                {block.fields.map(field => getFieldRow(field, block, idx, section))}
              </div>
            </Grid>
            {isNeedToRenderAddOwnerButton && (
              <Grid item xs={12}>
                <Button color="primary" onClick={handleAddOwner}>
                  Add Owner
                </Button>
              </Grid>
            )}
          </Grid>
        );
      });
    };

    return section.blocks.flatMap((block, blockIdx) => {
      if (!isEditMode && isBlockHidden(block, blockIdx)) {
        return null;
      }
      return block.type === blockTypes.owner
        ? getOwnersBlocks(block, section)
        : getSimpleBlock(block, blockIdx, section);
    });
  };

  const handleCreditAppPdfRequest = () => {
    //Open the URL on new Window
    const pdfWindow = window.open();
    const html = getCreditAppHTMLForPDF(
      sectionsWrapperEl.innerHTML,
      creditAppModalData,
      vendorProfile,
      account,
      userProfile,
      isIndividualCreditApp
    );

    // Helps to render on a separate tab
    // const blob = new Blob([html], { type: "text/html" });
    // const url = URL.createObjectURL(blob);
    // window.open(url);
    // return;

    axios
      .post(
        `${DCR_API_BASE_SERVER_URL}/html-to-pdf`,
        {
          html,
        },
        {
          responseType: "blob",
          headers: {
            "Content-Type": "application/json",
            Accept: "*/*",
          },
        }
      )
      .then(response => {
        //Create a Blob from the PDF Stream
        const file = new Blob([response.data], { type: "application/pdf" });
        //Build a URL from the file
        const fileURL = URL.createObjectURL(file);
        pdfWindow.location.href = fileURL;
      })
      .catch(error => console.log(error))
      .finally(() => {
        if (isForPDFOnly) {
          setIsPDFDownloaded(true);
        }
      });
  };

  const onCreditAppConvertTypeConfirmationModalClose = async (event, reason) => {
    if (_.includes(["backdropClick", "escapeKeyDown"], reason)) {
      return;
    }
    if (event.result !== "ok") {
      setIsCreditAppConvertTypeConfirmationModalOpen(false);
      return;
    }

    setIsCreditAppConvertTypeLoading(true);
    const input = {
      applicationType: creditAppTypes.commercial,
    };
    await updateCreditAppRequest({ variables: { creditAppId: creditAppModalData.id, input } });
    await creditAppModalDataRefetch();
    await refetchCreditAppOuter();
    setIsEditMode(false);
    setIsCreditAppConvertTypeConfirmationModalOpen(false);
    setIsCreditAppConvertTypeLoading(false);
  };

  const handleSubmit = async () => {
    try {
      const updateSalesRepIfNeeded = async () => {
        if (!_.some(voDetilsToUpdate?.salesRep)) {
          return;
        }
        await updateSalesRep({
          variables: {
            fieldsObj: voDetilsToUpdate.salesRep,
            VOId: vo.vendorOpportunityId,
            entityId: vo.dynamicsVendorOpportunityId,
          },
        });
      };

      const updateLocationIfNeeded = async () => {
        if (!_.some(voDetilsToUpdate?.location)) {
          return;
        }
        await updateDynamicsVOLocation({
          variables: {
            fieldsObj: {
              locationId: voDetilsToUpdate.location.locationId,
            },
            entityId: vo.dynamicsVendorOpportunityId,
          },
        });
        await updateVOLocation({
          variables: {
            fieldsObj: {
              locationId: voDetilsToUpdate.location.locationId,
            },
            VOId: vo.vendorOpportunityId,
          },
        });
      };

      const updateBusinessNameIfNeeded = async () => {
        if (!voDetilsToUpdate?.potentialCustomer) {
          return;
        }
        const potentialCustomerName = voDetilsToUpdate.potentialCustomer.name;

        if (potentialCustomerName || potentialCustomerName === "") {
          await updateAccountRequest({
            variables: {
              dynamicsId: vo.potentialCustomer.dynamicsAccountId,
              fieldsObj: {
                name: potentialCustomerName,
              },
            },
          });
          await updateAccountCRMRequest({
            variables: {
              entityId: vo.potentialCustomer.dynamicsAccountId,
              fieldsObj: {
                name: potentialCustomerName,
              },
            },
          });
        }
      };

      console.log(`detailsStateToSend: ${JSON.stringify(detailsStateToSend)}`);
      setCreditAppUpdateIsSubmitting(true);

      await updateSalesRepIfNeeded();
      await updateLocationIfNeeded();
      await updateBusinessNameIfNeeded();
      await updateCreditAppRequest({ variables: { creditAppId: creditAppModalData.id, input: detailsStateToSend } });

      await creditAppModalDataRefetch();
      await refetchCreditAppOuter();

      if (_.some(voDetilsToUpdate)) {
        await refetchVO();
      }
      if (isNeedToRefetchEquipmentData) {
        await refetchEquipment();
      }

      handleCloseConfirmationModal();
    } catch (err) {
      console.log(err);
    } finally {
      setVoDetilsToUpdate(null);
      setIsNeedToRefetchEquipmentData(false);
      setCreditAppUpdateIsSubmitting(false);
    }
  };

  const massageUpdateCreditAppInput = updateCreditAppInput => {
    const checkIfNeedToUpdateBusinessName = () => {
      if (_.has(updateCreditAppInput, "businessName")) {
        return {
          result: true,
          name: updateCreditAppInput.businessName,
        };
      }

      if (isIndividualCreditApp) {
        const applicant = creditAppModalData.primaryContact;
        const applicantUpdated = _.find(updateCreditAppInput.owners, { ownerPgId: applicant.ownerPgId });
        if (!!applicantUpdated) {
          const individualCreditAppKeysToReflect = ["firstName", "middleName", "lastName", "nameSuffix"];
          const newName = _(individualCreditAppKeysToReflect)
            .map(key => (_.has(applicantUpdated, key) ? applicantUpdated[key] : applicant[key]))
            .compact()
            .join(" ");
          return {
            result: true,
            name: newName,
          };
        }
      }

      return {
        result: false,
      };
    };

    let log = "";

    console.log(`updateCreditAppInput: ${JSON.stringify(updateCreditAppInput)}`);

    if (!updateCreditAppInput) {
      return;
    }

    if (updateCreditAppInput.monthBusinessIncorporated || updateCreditAppInput.yearBusinessIncorporated) {
      const yearBusinessIncorporatedToSet =
        updateCreditAppInput.yearBusinessIncorporated || getYearFromDateByKey("dateIncorporated");
      if (yearBusinessIncorporatedToSet) {
        const monthBusinessIncorporatedToSet =
          updateCreditAppInput.monthBusinessIncorporated || getMonthFromDateByKey("dateIncorporated") || "01";
        //15th used to fix issue when there may be a discrepancy with the server time and as a result the wrong month will be established
        updateCreditAppInput.dateIncorporated = `${monthBusinessIncorporatedToSet}-15-${yearBusinessIncorporatedToSet}`;
      }
    }
    if (updateCreditAppInput.monthBusinessEstablished || updateCreditAppInput.yearBusinessEstablished) {
      const yearBusinessEstablishedToSet =
        updateCreditAppInput.yearBusinessEstablished || getYearFromDateByKey("dateEstablished");
      if (yearBusinessEstablishedToSet) {
        const monthBusinessEstablishedToSet =
          updateCreditAppInput.monthBusinessEstablished || getMonthFromDateByKey("dateEstablished") || "01";
        //15th used to fix issue when there may be a discrepancy with the server time and as a result the wrong month will be established
        updateCreditAppInput.dateEstablished = `${monthBusinessEstablishedToSet}-15-${yearBusinessEstablishedToSet}`;
      }
    }

    const parseFieldsForPayload = (item, fields, parseFn) => {
      _.forEach(fields, fieldKey => {
        if (item[fieldKey] || item[fieldKey] === 0 || item[fieldKey] === false) {
          item[fieldKey] = parseFn(item[fieldKey]);
        }
      });
    };

    const creditAppStringifiedFields = ["timeAtPrevAddressYears", "timeAtPrevAddressMonths", "previousFinanceExp"];
    const ownersStringifiedFields = ["timeAtAddressYears", "timeAtAddressMonths"];
    const equipmentsStringifiedFields = ["year"];
    const referencesStringifiedFields = ["accountNum"];
    parseFieldsForPayload(updateCreditAppInput, creditAppStringifiedFields, _.toString);
    _.forEach(updateCreditAppInput.owners, o => parseFieldsForPayload(o, ownersStringifiedFields, _.toString));
    _.forEach(updateCreditAppInput.equipments, e => parseFieldsForPayload(e, equipmentsStringifiedFields, _.toString));
    _.forEach(updateCreditAppInput.references, r => parseFieldsForPayload(r, referencesStringifiedFields, _.toString));

    setIsNeedToRefetchEquipmentData(_.some(updateCreditAppInput.equipments));

    if (updateCreditAppInput.vendorContactId) {
      const selectedSalesRep = _.find(salesReps, { id: updateCreditAppInput.vendorContactId });
      setVoDetilsToUpdate(prevState => ({
        ...prevState,
        salesRep: {
          dynamicsSalesRepContactId: _.get(selectedSalesRep, "dynamicsContactId", null) || undefined,
          salesRepresentativeId: _.get(selectedSalesRep, "id", null) || undefined,
        },
      }));
    }

    if (updateCreditAppInput.locationId) {
      setVoDetilsToUpdate(prevState => ({
        ...prevState,
        location: {
          locationId: updateCreditAppInput.locationId,
        },
      }));
    }

    const checkIfNeedToUpdateBusinessNameResult = checkIfNeedToUpdateBusinessName();
    if (checkIfNeedToUpdateBusinessNameResult.result) {
      setVoDetilsToUpdate(prevState => ({
        ...prevState,
        potentialCustomer: {
          name: checkIfNeedToUpdateBusinessNameResult.name,
        },
      }));
      updateCreditAppInput.businessName = checkIfNeedToUpdateBusinessNameResult.name;
    }

    const setReferenceField = (referenceField, referenceType) => {
      const cadmV2ReferenceKeySuffix = "";
      if (updateCreditAppInput[`${referenceField.cadmV2Key}`]) {
        if (!updateCreditAppInput.references) {
          updateCreditAppInput.references = [];
        }
        let referenceIndex = updateCreditAppInput.references.findIndex(reference => {
          return reference.referenceType === referenceType;
        });
        if (referenceIndex === -1) {
          updateCreditAppInput.references.push({ referenceType });
          referenceIndex = updateCreditAppInput.references.length - 1;
          const referenceFromCreditApp = creditAppModalData.references.find(r => r.referenceType === referenceType);
          if (referenceFromCreditApp) {
            updateCreditAppInput.references[referenceIndex].referenceId = referenceFromCreditApp.referenceId;
          }
        }

        log += `\r\n${referenceType} referenceIndex: ${referenceIndex} | updateCreditAppInput.references: ${JSON.stringify(
          updateCreditAppInput.references
        )} [Found reference field ${referenceField.updateCreditAppV2Key} and set it to: ${
          updateCreditAppInput[`${referenceField.cadmV2Key}${cadmV2ReferenceKeySuffix}`]
        }`;
        updateCreditAppInput.references[referenceIndex][referenceField.updateCreditAppV2Key] =
          updateCreditAppInput[`${referenceField.cadmV2Key}${cadmV2ReferenceKeySuffix}`];
      }
    };

    // Trade References
    try {
      let tradeReferenceFields = [
        { cadmV2Key: "tradeReferenceName", updateCreditAppV2Key: "companyName" },
        { cadmV2Key: "tradeReferenceContactName", updateCreditAppV2Key: "contactName" },
        { cadmV2Key: "tradeReferenceContactEmail", updateCreditAppV2Key: "contactEmail" },
        { cadmV2Key: "tradeReferencePhone", updateCreditAppV2Key: "contactPhone" },
        { cadmV2Key: "tradeReferenceFax", updateCreditAppV2Key: "contactFax" },
        { cadmV2Key: "tradeReferenceAccountNum", updateCreditAppV2Key: "accountNum" },
        { cadmV2Key: "percRevenue", updateCreditAppV2Key: "percRevenue" },
        { cadmV2Key: "tradeReferenceYears", updateCreditAppV2Key: "years" },
        { cadmV2Key: "tradeReferenceMonths", updateCreditAppV2Key: "months" },
      ];

      /*
      MISSING FIELDS:
      referenceId: ID
      originalLoanAmt: Float
      lineOfCredit: Boolean
      creditLimit: Float
      currentBalance: Float
      monthlyPayment: Float
      renewalDate: String
      referenceType: ReferenceType
      */

      for (let i = 0; i < 3; i++) {
        let referenceType;
        if (i === 0) {
          referenceType = "TRADE";
        } else if (i === 1) {
          referenceType = "TRADE_TWO";
        } else if (i === 2) {
          referenceType = "TRADE_THREE";
        }
        tradeReferenceFields.forEach(tradeReferenceField => {
          const cadmV2ReferenceKeySuffix = i === 0 ? "" : (i + 1).toString();
          if (updateCreditAppInput[`${tradeReferenceField.cadmV2Key}${cadmV2ReferenceKeySuffix}`]) {
            if (!updateCreditAppInput.references) {
              updateCreditAppInput.references = [];
            }
            let referenceIndex = updateCreditAppInput.references.findIndex(reference => {
              return reference.referenceType === referenceType;
            });
            if (referenceIndex === -1) {
              updateCreditAppInput.references.push({ referenceType });
              referenceIndex = updateCreditAppInput.references.length - 1;
              const referenceFromCreditApp = creditAppModalData.references.find(r => r.referenceType === referenceType);
              if (referenceFromCreditApp) {
                updateCreditAppInput.references[referenceIndex].referenceId = referenceFromCreditApp.referenceId;
              }
            }

            log += `\r\n${referenceType} referenceIndex: ${referenceIndex} | updateCreditAppInput.references: ${JSON.stringify(
              updateCreditAppInput.references
            )} [Found reference field ${tradeReferenceField.updateCreditAppV2Key} and set it to: ${
              updateCreditAppInput[`${tradeReferenceField.cadmV2Key}${cadmV2ReferenceKeySuffix}`]
            }`;
            updateCreditAppInput.references[referenceIndex][tradeReferenceField.updateCreditAppV2Key] =
              updateCreditAppInput[`${tradeReferenceField.cadmV2Key}${cadmV2ReferenceKeySuffix}`];
          }
        });
      }
    } catch (e) {
      console.log(`Error updating trade reference fields: ${e.message}`);
    }

    // Bank Reference
    try {
      let bankReferenceFields = [
        { cadmV2Key: "primaryBankName", updateCreditAppV2Key: "companyName" },
        { cadmV2Key: "primaryBankContactName", updateCreditAppV2Key: "contactName" },
        { cadmV2Key: "primaryBankContactEmail", updateCreditAppV2Key: "contactEmail" },
        { cadmV2Key: "primaryBankPhone", updateCreditAppV2Key: "contactPhone" },
        { cadmV2Key: "primaryBankAccountNum", updateCreditAppV2Key: "accountNum" },
        { cadmV2Key: "primaryBankAverageBalance", updateCreditAppV2Key: "currentBalance" },
      ];

      bankReferenceFields.forEach(field => setReferenceField(field, "BANK"));
    } catch (e) {
      console.log(`Error updating BANK reference fields: ${e.message}`);
    }

    // Line of Credit (Loan) Reference
    try {
      let loanReferenceFields = [
        { cadmV2Key: "locReferenceCreditLimit", updateCreditAppV2Key: "companyName" },
        { cadmV2Key: "locReferenceCurrentBalance", updateCreditAppV2Key: "currentBalance" },
        { cadmV2Key: "locReferenceMonthlyPayment", updateCreditAppV2Key: "monthlyPayment" },
        { cadmV2Key: "locReferenceRenewalDate", updateCreditAppV2Key: "renewalDate" },

        { cadmV2Key: "loanReferenceName", updateCreditAppV2Key: "companyName" },
        { cadmV2Key: "loanReferenceContactName", updateCreditAppV2Key: "contactName" },
        { cadmV2Key: "loanReferenceContactEmail", updateCreditAppV2Key: "contactEmail" },
        { cadmV2Key: "loanReferenceMonthlyPayment", updateCreditAppV2Key: "monthlyPayment" },
      ];

      loanReferenceFields.forEach(field => setReferenceField(field, "LOAN"));
    } catch (e) {
      console.log(`Error updating Line of Credit LOAN reference fields: ${e.message}`);
    }

    // Primary Credit (Loan) Reference
    try {
      let loanReferenceFields = [
        { cadmV2Key: "primaryCreditName", updateCreditAppV2Key: "companyName" },
        { cadmV2Key: "primaryCreditContactName", updateCreditAppV2Key: "contactName" },
        { cadmV2Key: "primaryCreditContactEmail", updateCreditAppV2Key: "contactEmail" },
        { cadmV2Key: "primaryCreditPhone", updateCreditAppV2Key: "contactPhone" },
        { cadmV2Key: "primaryCreditAccountNum", updateCreditAppV2Key: "accountNum" },
        // { cadmV2Key: "primaryCreditAverageBalance", updateCreditAppV2Key: "averageBalance" },
      ];

      loanReferenceFields.forEach(field => setReferenceField(field, "LOAN"));
    } catch (e) {
      console.log(`Error updating Primary Credit LOAN reference fields: ${e.message}`);
    }

    // Line of Credit (LOC) Reference
    try {
      let lineOfCreditReferenceFields = [
        { cadmV2Key: "locReferenceCreditLimit", updateCreditAppV2Key: "creditLimit" },
        { cadmV2Key: "locReferenceCurrentBalance", updateCreditAppV2Key: "currentBalance" },
        { cadmV2Key: "locReferenceMonthlyPayment", updateCreditAppV2Key: "monthlyPayment" },
        { cadmV2Key: "locReferenceRenewalDate", updateCreditAppV2Key: "renewalDate" },
        { cadmV2Key: "locReferenceLineOfCredit", updateCreditAppV2Key: "lineOfCredit" },
      ];
      lineOfCreditReferenceFields.forEach(field => setReferenceField(field, "LOC"));
    } catch (e) {
      console.log(`Error updating Line of Credit (LOC) reference fields: ${e.message}`);
    }

    const ownersWithUpdatedSignatureDate = _.filter(updateCreditAppInput.owners, o =>
      _.some(signatureDateKeys, signatureKey => _.has(o, signatureKey))
    );
    const isSignatureDateWasUpdated = _.some(ownersWithUpdatedSignatureDate);
    setIsSignatureDateWasUpdated(isSignatureDateWasUpdated);
    if (isSignatureDateWasUpdated) {
      updateCreditAppInput.recertificationDate = new Date().toISOString();
      const recertificationHistoryToAdd = _.map(ownersWithUpdatedSignatureDate, updatedOwner => {
        const originalOwner = _.find(creditAppModalData.owners, { ownerPgId: updatedOwner.ownerPgId });
        const ownerFullName = _([
          updatedOwner.firstName || originalOwner.firstName,
          updatedOwner.lastName || originalOwner.lastName,
        ])
          .compact()
          .join(" ");
        return `Recertification for ${ownerFullName} on ${getDateTimeDisplayValue(new Date().getTime())} by ${
          userProfile.fullName
        }`;
      });
      updateCreditAppInput.recertificationHistory = _(creditAppModalData.recertificationHistory)
        .concat(recertificationHistoryToAdd)
        .compact()
        .value();
    }

    const keysToCleanup = [
      "monthBusinessIncorporated",
      "yearBusinessIncorporated",
      "monthBusinessEstablished",
      "yearBusinessEstablished",
      "vendorContactId",
    ];
    _.forEach(keysToCleanup, key => {
      delete updateCreditAppInput[key];
    });
    /*       Object.keys(updateCreditAppInput).forEach(inputKey => {
      if (inputKey === "owner") {
        // Don't delete owner, it is handled separately
        return;
      }
      if (inputKey === "references") {
        // Don't delete references, it is handled separately
        return;
      }
    }); */

    /*       const ownersAmbiguousMappings = {
      driverLicenseNum: "driversLicenseNum",
      percOwner: "ownerPercentage",
    };
    _.forEach(updateCreditAppInput.owners, owner => {
      const ownersAmbiguousMappingsKeys = _.keys(ownersAmbiguousMappings);
      _.forEach(owner, (value, key) => {
        if (_.includes(ownersAmbiguousMappingsKeys, key)) {
          _.set(owner, ownersAmbiguousMappings[key], value);
          delete owner[key];
        } else {
          _.set(owner, key, value);
        }
        if (!UPDATE_CREDIT_APPLICATION_V2_OWNER_TYPES[key]) {
          log += `\r\nRemoving updateCreditAppV2Input owners key ${key} because it isn't in UPDATE_CREDIT_APPLICATION_V2_OWNER_TYPES`;
          delete owner[key];
        }
      });
    }); */

    if (log !== "") {
      log = `Issues when updating Credit App V2\r\n${log}`;
      console.warn(log);
    }

    return updateCreditAppInput;
  };

  const handleConfirm = () => {
    setDetailsStateToSend(massageUpdateCreditAppInput(detailsState));
    setDetailsState({});
    setIsConfirmModalOpen(true);
  };

  const handleCloseConfirmationModal = () => {
    setIsEditMode(false);
    setIsConfirmModalOpen(false);
  };

  const isSectionHidden = section => {
    const isAllBlocksAreHidden = section =>
      _.every(section.blocks, (block, blockIdx) => isBlockHidden(block, blockIdx));
    const isSectionHiddenForCurrentRole = isItemHasRestrictionForRole(section, rolesSettingsKeys.hidden, userRole);
    return isSectionHiddenForCurrentRole || isAllBlocksAreHidden(section);
  };

  const isBlockHidden = (block, blockIdx) => {
    const getFieldsWithValues = (block, blockIdx) => {
      return _.filter(block.fields, field => {
        const value = getFieldValue(field, block, blockIdx);
        return value || value === 0;
      });
    };

    const isAllFieldsWithValuesAreHidden = fields => {
      return _.every(fields, field => isItemHasRestrictionForRole(field, rolesSettingsKeys.hidden, userRole));
    };

    const fieldsWithValues = getFieldsWithValues(block, blockIdx);
    const isBlockHiddenForCurrentRole = isItemHasRestrictionForRole(block, rolesSettingsKeys.hidden, userRole);
    return (
      isBlockHiddenForCurrentRole || _.isEmpty(fieldsWithValues) || isAllFieldsWithValuesAreHidden(fieldsWithValues)
    );
  };

  const getTileSubtitle = () => {
    const getRecertificationDateLayout = () => {
      const recertificationDate = _.get(creditAppModalData, "recertificationDate");
      return recertificationDate ? (
        <h6 className={classes.tileSubtitle}>Recertification Date - {getDateTimeDisplayValue(recertificationDate)}</h6>
      ) : null;
    };

    const getOCATemplateNameLayout = () => {
      const templateName = _.get(creditAppModalData, "ocaTemplate.name", "");
      const adminRolesToViewOCATemplateName = [adminRoles.super, adminRoles.singleAccountOnly];
      return _.includes(adminRolesToViewOCATemplateName, userAdminRole) && templateName ? (
        <h6 className={classes.tileSubtitle}>OCA Template - {_.get(creditAppModalData, "ocaTemplate.name", "")}</h6>
      ) : null;
    };

    return (
      <>
        {getRecertificationDateLayout()}
        {getOCATemplateNameLayout()}
      </>
    );
  };

  const handleAddOwner = () => {
    setCreditAppModalData({
      ...creditAppModalData,
      owners: [...creditAppModalData.owners, {}],
    });
  };

  const titleButtons = [
    !isEditingDisabled &&
      !isEditMode && {
        icon: <EditIcon />,
        props: {
          color: "transparent",
          style: { color: "grey", padding: 5 },
        },
        func: () => setIsEditMode(true),
      },
    !isEditMode && {
      icon: <span>PDF</span>,
      func: () => handleCreditAppPdfRequest(),
    },
    isIndividualCreditApp &&
      isEditMode && {
        text: <span>Convert to Business</span>,
        func: () => setIsCreditAppConvertTypeConfirmationModalOpen(true),
      },
  ];

  const isLoading = (creditAppLoading || loadingLocations || loadingSalesReps) && !creditAppUpdateIsSubmitting;
  return (
    <div className={classes.modalStyle}>
      <Tile
        maxHeightLimitByScreen={true}
        title={vo.entityType === "CASH_SALE" ? "Cash Sale Detail" : creditAppModalConfig.config.title}
        subtitle={getTileSubtitle()}
        titleButtons={isLoading ? [] : _.compact(titleButtons)}
      >
        {isLoading && (
          <div className={classes.circularProgressWrapper}>
            <CircularProgress />
          </div>
        )}
        {!isLoading && creditAppModalData && (
          <div ref={sectionsWrapperRef}>
            {creditAppModalConfig.sections.map(section => {
              if (isEditMode && section.config.title === "Authorization And Acceptance") {
                return null;
              }
              if (!isEditMode && isSectionHidden(section)) {
                return null;
              }
              return (
                <div
                  key={section.id}
                  className={
                    "sectionWrapper" + (section.type === sectionTypes.disclosures ? " disclosures-section" : "")
                  }
                >
                  <div className={classes.sectionTitle + " sectionTitle"}>
                    <h2>
                      {vo.entityType === "CASH_SALE"
                        ? section.config.title.replace("Finance", "")
                        : section.config.title}
                    </h2>
                    <div
                      className={classes.collapseButton + " collapseButton"}
                      onClick={() => toggleCollapse(section.id)}
                    >
                      {collapseState[section.id] ? (
                        <div className={classes.icon + " " + classes.iconPlus} />
                      ) : (
                        <div className={classes.icon + " " + classes.iconMinus} />
                      )}
                    </div>
                  </div>
                  {!collapseState[section.id] && <div className={classes.blocks}>{getBlocks(section)}</div>}
                </div>
              );
            })}
            {_.some(creditAppModalData.recertificationHistory) && (
              <div className={"hiddenOnPdf"}>
                <h3>Recertification History</h3>
                {creditAppModalData.recertificationHistory /* .reverse() */
                  .map((row, idx) => {
                    return <div key={idx}>{row}</div>;
                  })}
              </div>
            )}
          </div>
        )}
        {isEditMode && (
          <div className={classes.fieldRow}>
            <div className={classes.detailsCell + " " + classes.detailsLeftCol}>
              <Button onClick={() => setIsEditMode(false)}>Cancel</Button>
            </div>
            <div className={classes.detailsCell + " " + classes.detailsRightCol}>
              <Button color="success" onClick={handleConfirm}>
                Confirm Change
              </Button>
            </div>
            <CreditAppModifierConfirmModal
              open={isConfirmModalOpen}
              close={handleCloseConfirmationModal}
              handleSubmit={handleSubmit}
              creditAppUpdateIsSubmitting={creditAppUpdateIsSubmitting}
              additionalMessage={
                isSignatureDateWasUpdated &&
                "Please confirm you have recertified the below information with this applicant before proceeding."
              }
            />
          </div>
        )}
      </Tile>
      <Dialog
        open={isAuthLinkModalOpen}
        close={() => {
          setIsAuthLinkModalOpen(false);
          setTimeout(() => setAuthLinkOwner({}), 1000);
        }}
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle
          id="classic-modal-slide-title"
          disableTypography
          style={{ display: "flex", width: "100%", justifyContent: "space-between" }}
        >
          <h4>
            <b>Send Authorization Link</b>
          </h4>
          <Button
            justIcon
            key="close"
            aria-label="Close"
            color="transparent"
            onClick={() => {
              setIsAuthLinkModalOpen(false);
              setTimeout(() => setAuthLinkOwner({}), 1000);
            }}
          >
            <Close />
          </Button>
        </DialogTitle>
        {!creatingAuthLinkTask ? (
          <>
            {taskCreated ? (
              <DialogContent
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  minHeight: "300px",
                }}
              >
                <Check style={{ color: "#2e7d32", fontSize: 82 }} />
                <h5>Authorization Link Sent.</h5>
              </DialogContent>
            ) : (
              <>
                {!authLinkOwner.firstName || !authLinkOwner.lastName || !authLinkOwner.email ? (
                  <DialogContent style={{ display: "flex", flexDirection: "column" }}>
                    <h5>Not all information has been provided to send authorization link.</h5>
                    <ul>
                      {!authLinkOwner.firstName && <li>No first name.</li>}
                      {!authLinkOwner.lastName && <li>No last name.</li>}
                      {!authLinkOwner.email && <li>No email.</li>}
                    </ul>
                  </DialogContent>
                ) : (
                  <DialogContent style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                    <p>
                      Are you sure you would like to send an authorization link to {authLinkOwner.firstName}{" "}
                      {authLinkOwner.lastName}?
                    </p>
                  </DialogContent>
                )}
              </>
            )}
          </>
        ) : (
          <DialogContent
            style={{ display: "flex", alignItems: "center", justifyContent: "center", padding: 24, minHeight: "300px" }}
          >
            <CircularProgress style={{ width: 82, height: 82 }} />
          </DialogContent>
        )}
        <DialogActions style={{ display: "flex", width: "100%", justifyContent: "space-evenly" }}>
          {/* !authLinkOwner.invalidOwner && */ !taskCreated && !creatingAuthLinkTask && (
            <>
              <Button
                onClick={() => {
                  setIsAuthLinkModalOpen(false);
                  setTimeout(() => setAuthLinkOwner({}), 1000);
                }}
              >
                No
              </Button>
              <Button color="primary" onClick={() => handleAuthConfirm(authLinkOwner)}>
                Yes
              </Button>
            </>
          )}
        </DialogActions>
      </Dialog>

      <ConfirmationDialog
        title="Confirmation"
        messageLayout={() => (
          <>
            <Typography>
              Are you sure you want to change credit application type? This action cannot be undone.
            </Typography>
            <Typography variant="body2">
              <Info color="primary" style={{ verticalAlign: "bottom", margin: "4px 4px 0 0", width: 20 }} />
              Only use this when an Applicant applied as an <strong>Individual</strong>, but are really a{" "}
              <strong>Business</strong> Applicant
            </Typography>
          </>
        )}
        okButtonText="Proceed"
        open={isCreditAppConvertTypeConfirmationModalOpen}
        onClose={onCreditAppConvertTypeConfirmationModalClose}
        loading={isCreditAppConvertTypeLoading}
        titleCentered={true}
        buttonsCentered={true}
      />
    </div>
  );
}
